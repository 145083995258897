<template>
  <div>
    <template v-if="route == 'select_school'">
      <router-view />
    </template>
    <template v-else>
      <Header
        :toggleSideNav="toggleSideNav"
        :toggleMobSideBar="toggleMobSideBar"
      />
      <div class="site-main-container">
        <Sidebar
          :toggleMobSideBar="toggleMobSideBar"
          :toggleSideNav="toggleSideNav"
        />
        <div class="content col-12 col-md-11">
          <router-view />
        </div>
      </div>
    </template>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Sidebar from "../../components/Sidebar.vue";
import Footer from "../../components/Footer.vue";

export default {
  components: { Header, Sidebar, Footer },
  data() {
    return {
      toggleMobSideBar: false,
    };
  },
  methods: {
    toggleSideNav() {
      this.toggleMobSideBar = !this.toggleMobSideBar;
    },
  },
  computed: {
    route() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss">
.site-main-container {
  display: flex;
  flex-direction: row;
  position: relative; z-index: 120;
  @media (max-width: 959px) {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .side-bar {
    border-left: 1px solid #ddd;
  }
  .content {
    border-right: 1px solid #ddd;
    padding-bottom: 50px;
  }
}
.page-wrap {
  padding-bottom: 50px;
  .head {
    h1 {
      text-align: center;
      color: #0f707b;
      line-height: 1;
    }
  }
  @media all and (max-width: 1023px){
    .head h1{font-size: 40px;}
  }
  @media all and (min-width: 1024px){
    .head h1{font-size: 60px;}
  }
}
</style>
