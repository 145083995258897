<template>
  <div
      class="modal"
      v-bind:class="[
      toggleModal === true
        ? 'active-auth'
        : '' || currentCartDesc != undefined
        ? 'active-desc'
        : null,
    ]"
  >
    <loader style="z-index: 1; background: transparent;" v-if="isPopupLoading"></loader>
    <div v-if="toggleModal" class="modal-container-auth d-flex flex-column">
      <div class="close" @click="authModal" >
         <img src="@/assets/icons/close.svg" alt="close" />
      </div>
      <header>
        <img src="@/assets/icons/mekuvanmodal.svg" alt=""/>
      </header>
      <div class="circels d-flex flex-row">
        <a class="google" :href="getGoogleURL">
          <img src="@/assets/icons/google.svg" alt=""/>
        </a>
        <a class="owl" :href="getIdmURL">
          <img src="@/assets/icons/owla.svg" alt=""/>
        </a>
      </div>
      <div class="line-wrapper">
        <div class="line d-flex flex-row">
          <div class="l"></div>
          <h1>{{$t('OR')}}</h1>
          <div class="r"></div>
        </div>
        <div v-if="errorInLogin" class="error-msg">
          {{ $t('ERROR_AT_DETAILS') }}
        </div>
        <form class="d-flex flex-column">
          <input type="text" :placeholder="$t('USERNAME')" v-model="userName"
                 @input="cleanErrorInLogin(); $v.userName.$touch()"
                 :class="{ error: $v.userName.$error }"/>
          <input type="password" :placeholder="$t('PASSWORD')" v-model="password"
                 @input="cleanErrorInLogin(); $v.password.$touch()"
                 :class="{ error: $v.password.$error }"/>
          <span @click="openResetPassword" class="forgot-pass">{{$t('FORGET_PASSWORD')}}</span>
          <button @click="login($event)" :class="hasErrors ? 'disabled' : null">{{$t('ENTER')}}</button>
        </form>
      </div>
    </div>
    <div v-if="currentCartDesc" class="modal-container-desc d-flex flex-column">
      <div class="d-flex flex-row head-modal">
        <p>{{ currentCartDesc.title }}</p>
        <div
            @click="showDescCart(null)"
            class="close"   
        >
          <img src="@/assets/icons/closewhite.svg" alt="closewhite" />
        </div>
      </div>
      <div class="description" :class="{ recomended: useBy === true }">
        <p v-html=" currentCartDesc.description"/>
      </div>
      <div class="btn-task">
        <button @click="openEntryToPreview({entry: currentCartDesc, isContentUnit})">{{$t('ENTER_TO_TASK')}}</button>
      </div>
    </div>
      <h1 v-if="DeleteTask">{{$t('DELETE')}}</h1>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { required } from 'vuelidate/lib/validators'
  import Loader from '@/components/Loader'
  import store from '../store'
  import { MUTATION_TYPES } from '../store/types'
  import helpers from '@/helpers'

  export default {
    components: { Loader },
    props: [
      'isContentUnit',
      'toggleModal',
      'authModal',
      'currentCartDesc',
      'showDescCart',
      'useBy',
      'DeleteTask'
    ],
    name: 'modal',
    data () {
      return {
        userName: null,
        password: null,
        captcha: null,
        siteKey: helpers.env('HCAPTCHA_SITE_KEY'),
        errorInLogin: null
      }
    },
    validations: {
      userName: {
        required
      },
      password: {
        required
      }
    },
    computed: {
      ...mapState({
        isPopupLoading: state => state.isPopupLoading,
        librarySlug: state => state.librarySlug
      }),
      hasErrors () {
        return !this.$v.userName.required || !this.$v.password.required || Boolean(this.errorInLogin)
      },
      getGoogleURL () {
        return helpers.getGoogleURL()
      },
      getIdmURL () {
        return helpers.getIdmURL()
      },
    },
    methods: {
      ...mapActions(['openEntryToPreview']),
      verifyCaptcha (response) {
        this.captcha = response
      },
      openResetPassword () {
        this.authModal()
        this.$router.replace({ name: 'resetPassword' })
      },
      login (event) {
        event.preventDefault()
        if (!this.$v.password.required || !this.$v.userName.required || this.errorInLogin) {
          return
        }
        this.$store.dispatch('setPopupLoading', true)
        this.$auth.auth(this.userName, this.password, this.captcha)
          .then((response) => {
            response = response.data
            store.commit(MUTATION_TYPES.SET_USER, { token: response.access_token, user: null })
            const getArbSchool = this.librarySlug === 'ramot-arb'
            this.$store.dispatch('fetchAuthenticatedUser', getArbSchool)
            this.$store.dispatch('setPopupLoading', false)
          }).catch(error => {
          this.$store.dispatch('setPopupLoading', false)
          this.errorInLogin = true
        })
      },
      cleanErrorInLogin () {
        this.errorInLogin = null
      }
    }
  }
</script>
<style lang="scss">
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: #0000005e;
    align-items: center;
    justify-content: center;
    display: none;

    &.active-auth {
      display: flex;
      z-index: 999;
      text-align: left;

      .close {
        margin-left: 10px;
      }
    }

    &.active-desc {
      display: flex;
      z-index: 999;
    }

    .modal-container-auth {
      width: 943px;
      background: white;
      border-radius: 25px;
      padding: 15px 0;
      -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      position: relative;

      .error-msg {
        text-align: center;
        color: #ff0000;
        font-size: 20px;
      }

      @media all and (min-width: 760px) and (max-width: 959px) {
        width: 743px;
      }
      @media all and (min-width: 660px) and (max-width: 759px) {
        width: 643px;
      }
      @media all and (min-width: 580px) and (max-width: 659px) {
        width: 543px;
      }
      @media all and (min-width: 501px) and (max-width: 579px) {
        width: 443px;
      }
      @media (max-width: 500px) {
        width: 400px;
      }

      .close {
        position: absolute; top: 15px; inset-inline-end: 10px;
        margin-left: 15px;
        @media screen and (max-width: 767px){
          top: 25px;
        }
        @media screen and (min-width: 768px){
          top: 15px;
        }
      }

      header {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          text-align: center;
        }
      }

      .circels {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0;

        .google {
          width: 73px;
          height: 73px;
          border-radius: 50%;
          background: #d5d5d5;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 25px;

          .x {
            width: 100px;
            height: 100px;
          }
        }

        .owl {
          width: 73px;
          height: 73px;
          border-radius: 50%;
          background: #4fb6f7;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 25px;
          cursor: pointer;

          img {
            margin-top: 15px;
          }
        }
      }

      .line-wrapper {
        display: block;
        margin: 0 auto;
        padding-bottom: 20px;
      }

      .line {
        width: 460px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;
        @media (max-width: 579px) {
          width: 330px;
        }

        .l {
          width: 40%;
          height: 2px;
          border-top: 1px solid #504e4e;
        }

        h1 {
          margin: 0 15px;
          font-size: 20px;
          color: #504e4e;
        }

        .r {
          width: 40%;
          height: 2px;
          border-top: 1px solid #504e4e;
        }
      }

      form {
        input {
          height: 47px;
          border: 1px solid #707070;
          border-radius: 15px;
          font-size: 20px;
          padding: 0 15px;
          margin: 10px 0;
          outline: none;
        }

        .error {
          border: 2px solid red;
          background-color: transparent !important
        }

        .forgot-pass {
          padding: 0 15px;
          color: #0f707b;
          font-size: 20px;
          padding-bottom: 8px;
          cursor: pointer;
          margin-top: -10px;
          &:hover{
            text-decoration: underline;
          }
        }

        button {
          background: #2196f3;
          color: white;
          text-align: center;
          font-size: 30px;
          border-radius: 17px;
        }

        .disabled {
          background: #d5d5d5;
          pointer-events: none;
        }

        .have-account {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px 0;

          span {
            font-size: 20px;
            margin: 0 10px;
          }

          span:nth-child(2) {
            color: #1ab2c4;
          }
        }
      }
    }

    @-webkit-keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }
    @keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }

    .modal-container-desc {
      max-width: 443px;
      background: white;
      border-radius: 25px;
      -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      .close {
        height: 100%;
        padding: 8px 0;

        svg {
          width: 15px;
          height: 15px;
          margin-top: 8px;
        }
      }

      .info-box {
        width: 55px;
        height: 55px;
        border-top-left-radius: 15px;
        background: #469ca4;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 34.2px;
        color: wheat;
        z-index: 999;
      }

      .description {
        width: 600px;
        height: 25vh;
        overflow: auto;

        &.recomended {
          width: 380px;
        }
      }

      .btn-task {
        padding: 15px 20px;
        text-align: left;
        @media (max-width: 768px) {
          padding: 25px 20px;
        }

        button {
          width: 153px;
          height: 40px;
          background: #0f707b;
          cursor: pointer;
          border-radius: 30px;
          color: white;
          font-size: 18px;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }

    @-webkit-keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }
    @keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }

    .modal-container-desc {
      background: white;
      border-radius: 15px;
      width: 95%;
      @media screen and (min-width: 960px){
        max-width: 650px;
      }
      @media (min-width: 601px) and (max-width: 959px) {
        max-width: 550px;
      }
      @media (min-width: 541px) and (max-width: 600px) {
        max-width: 510px;
      }
      @media (min-width: 501px) and (max-width: 540px) {
        max-width: 470px;
      }
      @media (min-width: 441px) and (max-width: 500px) {
        max-width: 400px;
      }
      @media (max-width: 440px) {
        max-width: 390px;
      }

      .close {
        img {
          width: 15px;
          height: 15px;
        }
      }

      .head-modal {
        background: #1ab2c4;
        padding: 0px 20px;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;

        p {
          font-size: 30px;
          color: white;
          margin-bottom: 0;
          font-weight: bold;
          padding: 6px 0;
        }

        .close {
          svg {
            width: 20px;
            height: 20px;
            margin-top: 8px;
          }
        }
      }

      .description {
        width: 100%;
        height: 200px;
        overflow: auto;
        padding: 15px;

        p {
          font-size: 18px;
        }
      }

      ::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #1ab2c4;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .captcha {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
    }
  }
</style>
