<template>
  <div class="page-wrap books-page">
    <loader style="z-index: 1;" v-if="loading"></loader>
    <div class="head">
      <div class="main-title">
        <h1 class="top-title">{{isTeacher?$t('DIGITAL_TEXTBOOKS'):$t('DIGITAL_TEXTBOOK')}}</h1>
        <div class="title-items">
         <figure v-if="isTeacher">
           <img src="@/assets/icons/chart_grey.svg" class="chart-icon" alt="chart" @click="openUnitReport"/>
           <figcaption class="chart-caption">
             {{$t('RESULTS')}}
           </figcaption>
         </figure>
          <div class="mail-wrap" @click="feedbackList.length? openFeedbackList=!openFeedbackList: false">
            <span v-show="feedbackList.length" class="circle-for-feedback">{{feedbackList.length}}</span>
            <figure>
              <img src="@/assets/icons/toolmail.svg" alt=""/>
              <figcaption class="mail-caption">{{$t('FEEDBACK')}}</figcaption>
            </figure>
          </div>
        </div>
        <div class="feedback-box" v-if="openFeedbackList">
          <div class="feedback-item" v-for="(feedback,index) in feedbackList" @click="goToFeedback({feedback, index})">
            <span v-if="feedback.nameOfSendMailUser">{{feedback.nameOfSendMailUser}}</span>
            <span>{{feedback.nameUnit}}</span>
            <p>{{feedback.feedback}}</p>
          </div>
        </div>
      </div>
      <div class="sub-head d-flex flex-row align-center justify-center">
        <img
            class="icon-auth"
            src="@/assets/icons/mabat-png.svg"
            alt=""
        />
        <h2>{{$t('SCIENCE_AND_TECHNOLOGY')}} {{$t('TO')}} </h2>
        <div class="select-wrap">
          <select v-model="selectedClass" name="classes" id="classes" class="select-class">
            <option
                :value="classItem.id"
                :key="index"
                v-for="(classItem, index) in classes"
            >
              {{ classItem.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="books-container">
        <div class="book-item" v-for="(element, index) in getBooks" :key="index">
          <div class="task-item books"
               @click="openEntryInNewWindow(element)">
            <div :style="{ backgroundImage: 'url(' + element.image + ')' }" class="card-bg"></div>
            <div class="d-flex flex-column align-center content-wrapper">
              <div class="col-12">
                <div class="top-wrap"></div>
                <div class="bottom-wrap">
                  <p class="name">{{ element.name }}</p>
                  <img v-if="isTeacher" src="@/assets/icons/chart_white.svg" alt=""
                       @click="openLessonReport($event, element)"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
  import helpers from '@/helpers'
  import Loader from '../../../components/Loader'

  export default {
    name: 'books',
    components: { Loader },
    data () {
      return {
        openFeedbackList: false,
        books: [],
        loading: false,
      }
    },
    created () {
      this.$store.dispatch('getFeedback')
    },
    mounted () {
      this.$store.dispatch('setSelectedClass', this.classes[0].id)
      this.loading = true
      this.user.fetchData(this.$route.name, {
        classId: this.classes[0].id,
      }).then((result) => {
        this.books = result
        this.loading = false
      })
    },
    watch: {
      async selectedClass () {
        this.loading = true

        this.books = await this.user.fetchData(this.$route.name, {
          classId: this.selectedClass,
        })

        this.loading = false
      },
    },
    computed: {
      ...mapState({
        feedbackList: state => state.feedbackList,
        librarySlug: state => state.librarySlug,
      }),
      selectedClass: {
        get () {
          return this.$store.state.selectedClass
        },
        set (value) {
          this.$store.dispatch('setSelectedClass', value)
        }
      },
      user () {
        return this.$auth.user
      },
      classes () {
        return this.$store.getters['classes']
      },
      isTeacher () {
        return this.user.isTeacher()
      },
      getBooks () {
        if (this.isTeacher) {
          return this.books
        } else {
          return this.books.filter(b => b.typelesson.name != 'ONLY_TO_TEACHER')
        }
      }
    },
    methods: {
      ...mapActions(['goToFeedback']),
      openEntryInNewWindow (lesson) {
        const lang = this.librarySlug === 'ramot-heb' ? 'he' : 'arb'
        const baseUrl = helpers.env('LEGACY_CLASSE_HOST')
        const url = baseUrl + '/teacher/#!/' + lang + '/session/' + lesson.unit_id + '/playLesson/' + lesson.id + '/'
        this.$store.dispatch('openEntryInNewWindow', url)
      },
      openLessonReport (event, lesson) {
        event.stopPropagation()
        const lang = this.librarySlug === 'ramot-heb' ? 'he' : 'arb'
        const baseUrl = helpers.env('LEGACY_CLASSE_HOST')
        const url = baseUrl + '/teacher/#!/' + lang + '/session/containerReport/' + lesson.unit_id + '/' + lesson.id + '/gradeLessonReport/'
        this.$store.dispatch('openEntryInNewWindow', url)
      },
      openUnitReport () {
        const baseUrl = helpers.env('LEGACY_CLASSE_HOST')
        const lang = this.librarySlug === 'ramot-heb' ? 'he' : 'arb'
        const url = baseUrl + '/teacher/#!/' + lang + '/session/containerReport/' + this.books[0].unit_id + '//gradeUnitReport/'
        this.$store.dispatch('openEntryInNewWindow', url)
      }
    },

  }
</script>
<style lang="scss">
.books-page {
  .sub-head {
    display:flex; gap: 8px;
    h2 {
      font-size: 55px;
      text-align: center;
      color: #0f707b;
      font-weight: 300;
    }
    .select-wrap {
      display: flex; align-items: center; gap: 8px;
      background: #efefef;
      font-size: 21px;
      border-radius: 5px;
      @media (min-width: 600px){
        padding: 5px 10px;
      }
      @media (max-width: 599px) {
        padding: 0 15px;
        outline: none;
      }
    }
  }
  .books-container {
    display: grid; grid-template-columns: repeat(auto-fit, minmax(280px, 310px)); gap: 25px;
    height: 100%;
    padding-bottom: 25px;
    margin-top: 15px;
    .task-item {
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
        height: 300px;
        display: block;
        object-fit: cover;
      }
      .content-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0;
        top: 0;
        border: none;
        background: linear-gradient(0deg, rgba(50,49,49,0.6615021008403361) 12%, rgba(255,255,255,0) 35%);
        z-index: 1;
        .col-12 {
          border-bottom: 1px solid rgba(255, 255, 255, 0.322);
          color: white;
          font-size: 27px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          .top-wrap {
            height: 80%;
          }
          .bottom-wrap {
            height: 20%;
            display: flex;
            align-items: center;
          }
          .name{
            font-size: 20px;
            font-weight: normal;
            line-height: 1;
            color: white;
            margin-bottom: 0;
          }
          img {
              width: 20px;
              height: 20px;
              cursor: pointer;
              margin-inline-end: 5%;
              margin-inline-start: auto;
            }
          }
        }
      }
    .scope{
      width: 167.6px;
      height: 97px;
      object-fit: contain;
      position: absolute;
      inset-inline-end: 0;
      top: 43%;
      opacity: 1;
    }
    }
  }

.books .content-wrapper{border-radius: 10px;}
.select-class{
  cursor: pointer; -webkit-appearance: inner-spin-button;
}
.main-title{
  display: flex;
  .top-title{width: 100%; text-align: center;}
  .chart-icon{
    margin-inline-start: auto; cursor: pointer; width: 33px; margin: 0 auto;
  }
  .chart-caption{font-size: 18px;}
}
select:focus{outline: none!important;}
.title-items{
  display: flex; align-items: center; gap: 15px;
  .mail-caption{
    font-size: 18px;
  }
}
figure{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;
  figcaption{text-align: center;}
}
@media all and (max-width: 1023px) {
  .sub-head{
    flex-wrap: wrap;
  }
}
</style>
