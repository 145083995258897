<template>
  <div class="auth">
    <Header :toggleSideNav="false"/>
    <div class="auth-container">
      <header>
        <h1>{{$t('HELLO')}} {{ user.first_name }}!</h1>
        <h4>{{$t('SELECT_SCHOOL_HEADER')}}</h4>
        <h5>{{$t('SELECT_SCHOOL_DATA')}}</h5>
      </header>
      <div class="options">
        <div
            class="d-flex flex-column align-center options-wrapper"
            :class="{ active: activeSchool === index }"
            v-for="(item, index) in user.schools"
            :key="index"
        >
          <div class="item" @click="activeSchool = index">
            <p>{{ item.name }}</p>
          </div>
        </div>
        <button @click="selectSchool" :class="{'disable': !activeSchool && activeSchool !== 0}">{{$t('ENTER')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from '../../../components/Header.vue'
  import { MUTATION_TYPES } from '../../../store/types'
  import { mapGetters } from 'vuex'

  export default {
    name: 'auth',
    components: { Header },
    data () {
      return {
        activeSchool: false,
      }
    },
    methods: {
      async selectSchool () {
        this.$store.commit(MUTATION_TYPES.SET_SCHOOL, this.activeSchool)
        await this.$router.replace({ name: 'books' })
      },
    },
    computed: {
      user () {
        return this.$auth.user
      },
      ...mapGetters(['school'])
    },
  }
</script>
<style lang="scss">
  .auth {
    .auth-container {
      max-width: 1200px;
      margin: 0 auto;

      header {
        text-align: center;
        padding: 45px 0;

        h1 {
          font-size: 60px;
          text-align: center;
          color: #0f707b;
        }

        h4 {
          font-size: 40px;
          color: #504e4e;
          font-weight: 300;
        }

        h5 {
          font-size: 28px;
          color: #504e4e;
          font-weight: 200;
        }
      }

      .options {
        max-width: 600px;
        margin: 0 auto;

        .options-wrapper {
          &.active {
            .item {
              background: #e8fbfe;
            }
          }

          .item {
            width: 550px;
            border: 1px solid #707070;
            border-radius: 25px;
            padding: 0 15px;
            margin: 8px 0;
            cursor: pointer;

            &:hover {
              background: #e8fbfe;

              p {
                color: black;
              }
            }

            p {
              font-size: 30px;
              font-weight: 300;
              margin-bottom: 0px;
            }
          }
        }

        button {
          width: 226px;
          background: #2196f3;
          font-size: 40px;
          color: white;
          border-radius: 25px;
          margin: 30px auto;
          display: block;
        }

        .disable {
          background: #cfc9c9; pointer-events:none;
        }
      }
    }
  }

  @media (max-width: 959px) {
    .auth {
      .auth-container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 25px;

        header {
          text-align: center;
          padding: 45px 0;

          h1 {
            font-size: 60px;
            text-align: center;
            color: #0f707b;
            padding-top: 50px;
          }

          h4 {
            font-size: 40px;
            color: #504e4e;
            font-weight: 300;
          }

          h5 {
            font-size: 24px;
            color: #504e4e;
            font-weight: 200;
          }
        }

        .options {
          max-width: 100%;
          width: 100%;
          margin: 0 auto;

          .options-wrapper {
            .item {
              width: 100%;
              border: 1px solid #707070;
              border-radius: 25px;
              padding: 0 15px;
              margin: 8px 0;
              cursor: pointer;

              &:hover {
                background: #e8fbfe;

                p {
                  color: black;
                }
              }

              p {
                font-size: 22px;
                font-weight: 300;
                margin-bottom: 0px;
              }
            }
          }

          button {
            width: 226px;
            background: #2196f3;
            font-size: 30px;
            color: white;
            border-radius: 25px;
            margin: 30px auto;
            display: block;
          }

          .disable {
            background: #cfc9c9;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .auth {
      .auth-container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 25px;

        header {
          text-align: center;
          padding: 45px 0;

          h1 {
            font-size: 60px;
            text-align: center;
            color: #0f707b;
          }

          h4 {
            font-size: 40px;
            color: #504e4e;
            font-weight: 300;
          }

          h5 {
            font-size: 22px;
            color: #504e4e;
            font-weight: 200;
          }
        }

        .options {
          max-width: 100%;
          width: 100%;
          margin: 0 auto;

          .options-wrapper {
            .item {
              width: 100%;
              border: 1px solid #707070;
              border-radius: 25px;
              padding: 0 15px;
              margin: 8px 0;
              cursor: pointer;

              &:hover {
                background: #e8fbfe;

                p {
                  color: black;
                }
              }

              p {
                font-size: 22px;
                font-weight: 300;
                margin-bottom: 0px;
              }
            }
          }

          button {
            width: 226px;
            background: #2196f3;
            font-size: 30px;
            color: white;
            border-radius: 25px;
            margin: 30px auto;
            display: block;
          }

          .disable {
            background: #cfc9c9;
          }
        }
      }
    }
  }
</style>
