<template>
  <div class="footer">
    <img alt="" class="turbina" src="@/assets/icons/turbina.gif"/>
    <div class="footer-content d-flex flex-column">
      <div class="footer-container d-flex flex-row">
        <div class="d-none d-md-flex card-footer">
          <v-img
            contain
            lazy-src="@/assets/icons/scope.svg"
            max-height="200"
            max-width="200"
            src="@/assets/icons/scope.svg"
          ></v-img>
        </div>
        <div class="col-12 col-md-4 card-footer">
          <div class="d-flex flex-row">
            <div class="col-3">
              <v-img
                contain
                lazy-src="@/assets/icons/mabatfooter.svg"
                max-height="100"
                max-width="100"
                src="@/assets/icons/mabatfooter.svg"
              ></v-img>
            </div>
            <div class="col-3">
              <v-img
                contain
                lazy-src="@/assets/icons/univfooter.svg"
                max-height="100"
                max-width="100"
                src="@/assets/icons/univfooter.svg"
              ></v-img>
            </div>
            <div class="col-3">
              <v-img
                cover
                lazy-src="@/assets/icons/ramot.png"
                max-height="100"
                max-width="100"
                src="@/assets/icons/ramot.png"
              ></v-img>
            </div>
          </div>
          <div class="lorem">
            {{$t('SITE_FOOTER_EXPLANATION')}}.
          </div>
          <router-link :to="{name: 'About'}" @click.native="$scrollToTop">
            {{$t('MORE_DATA')}}
          </router-link>
          <div class="social">
            <a href="https://wa.me/972508848255" target="_blank">
              <img src="../assets/icons/whatsapp_icon.svg" alt="">
            </a>
            <a href="https://www.facebook.com/mabatmekuvan/" target="_blank">
              <img src="@/assets/icons/facebook_icon.svg" alt="">
            </a>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 card-footer">
          <h4>{{$t('FOR_INQUIRIES')}}</h4>
          <div class="wrap d-flex flex-row">
            <img src="@/assets/icons/mail.svg" alt="" style="display: none;">
            <a href="mailto:anat.samkai@ramot.org">anat.samkai@ramot.org</a>
          </div>
          <div class="wrap d-flex flex-row">
            <img src="@/assets/icons/phone.svg" style="display: none; max-width: 20px; max-height: 20px; margin-inline-end: 7px;" alt="">
            <a href="tel://050-8848255" class="tel">050-8848255</a>
          </div>
          <div class="wrap d-flex flex-row">
            <img src="@/assets/icons/file.svg" style="display: none; width: 15px;" alt="file icon">
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 card-footer">
          <h4>{{$t('TECHNICAL_SUPPORT')}}</h4>
          <div class="wrap d-flex flex-row">
            <img src="@/assets/icons/mail.svg" style="display: none;" alt="">
            <a href="mailto:support@classe.world">support@classe.world</a>
          </div>
          <div class="wrap d-flex flex-row">
            <img src="@/assets/icons/phone.svg" style="display: none; max-width: 20px; max-height: 20px;" alt="">
            <a href="tel://052-5665706">052-5665706</a>
          </div>
          <div class="wrap d-flex flex-row">
            <img src="@/assets/icons/play-circle.svg" style="display: none; max-width: 20px; max-height: 20px;" alt="">
            <router-link :to="{name: 'Videos'}" @click.native="$scrollToTop">
              {{$t('INSTRUCTIONAL_VIDEOS')}}
            </router-link>
          </div>
        </div>
      </div>
      <div class="bottom-wrapper">
        <div
          class="d-flex flex-column flex-md-row flex-row align-center justify-center"
        >
          <div class="col-2s">
            <div class="sub-card">
              <a href="https://www.classe.world/" target="_blank"
                >Powered by Class-E</a
              >
            </div>
          </div>
          <div class="col-2s">
            <div class="sub-card b-r">
              {{$t('COPYRIGHT_EXTENSION')}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer",
  data() {
    return {
      footer: [
        {
          title: this.$t('FOR_INQUIRIES'),
          email: "support@classe.world",
          phone: "03-6424106",
          imgmail: "mail",
          imgcall: "phone",
          link: false,
        },
        {
          title: this.$t('FOR_INQUIRIES'),
          email: "support@classe.org",
          phone: "03-6424106",
          imgmail: "mail",
          imgcall: "phone",
          link: false,
        },
        {
          title: this.$t('TECHNICAL_SUPPORT'),
          email: "supportmabat@ramot.org",
          phone: "055-9396492",
          imgmail: "mail",
          imgcall: "phone",
          link: this.$t('INSTRUCTIONAL_VIDEOS'),
          link: true,
        },
      ],
      subfooter: [
        { title: this.$t('COPYRIGHT') },
        { title: "Powered by Class-E" },
        { title: this.$t('TERMS_OF_USE') },
      ],
    };
  },
};
</script>

<style lang="scss">
.footer {
  z-index: 110;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  margin-top: 23vh;
  &-content{
    background-image: url("../assets/media/footerbg.png");
    background-repeat: no-repeat; background-size: cover;
    width: 100%;
  }
  .turbina {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 350px;
    display: flex;
    @media (max-width: 959px) {
      display: none;
    }
  }
@media screen and (max-width: 959px){
  &-content{background-position: 100% 0; padding-top: 270px;}
}
@media screen and (min-width: 960px){
  &-content{background-position: top right; height: 490px;}
}
  @media screen and (min-width: 1600px){
  &-content{padding-top: 60px;}
}
  .footer-container {
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    @media (max-width: 959px) {
      flex-wrap: wrap;
    }
    @media (min-width: 960px) {
      gap: 20px;
    }
    .card-footer {
      align-items: flex-start;
      .col-4 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .col-3 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        @media (max-width: 1450px) {
          margin: 0 10px;
        }
         @media (max-width: 1250px) {
          margin: 0 15px;
        }
      }
      .lorem {
        opacity: 0.7;
        font-size: 19px;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: right;
        color: #3e3e3e;
        margin-top:50px;
      }
      h4 {
        font-size: 27px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.96;
        letter-spacing: normal;
        text-align: right;
        color: #434343;
        padding-bottom: 15px;
        margin-inline-start: 7px;
      }
      .wrap {
        display: flex;
        align-items: center;
        p {
          font-size: 23px;
          color: #3e3e3e;
          margin-bottom: 0;
          margin-right: 8px;
        }
        a {
          margin-bottom: 0;
          font-size: 23px;
          color: #3e3e3e;
          margin-right: 8px;
          &.tel{white-space: nowrap; margin-right: unset; margin-inline-start: 7px;}
        }
        .slash{display: block; margin-inline: 5px;}
      }
      .col-1,
      .col-2,
      .col-3,
      .col-4,
      .col-5 {
        padding: 0;
      }
    }
  }
  .bottom-wrapper {
    width: 100%;
    padding-bottom: 15px;
    .sub-card {
      font-size: 20px;
      height: 4vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 25px;
      color: #939393;
    }
    a {
      color: #939393;
    }
    .b {
      border-inline: 2px solid#939393;
    }
    .b-r {
      border-right: 2px solid #939393;
    }
  }
}
@media (max-width: 1440px) {
  .card-footer {
    .col-4 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .lorem {
      opacity: 0.7;
      font-size: 19px;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: right;
      color: #3e3e3e;
      max-width: 60%;
      margin-top: 15px;
    }
    h4 {
      font-size: 23px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: normal;
      text-align: right;
      color: #434343;
      padding-bottom: 15px;
      margin-inline-start: 7px;
    }
    .wrap {
      display: flex;
      align-items: center;
      p {
        font-size: 19px;
        color: #3e3e3e;
        margin-bottom: 0;
        margin-right: 8px;
      }
      a {
        margin-bottom: 0;
        font-size: 23px;
        color: #3e3e3e;
        margin-right: 8px;
      }
    }
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5 {
      padding: 0;
    }
  }
}
@media (max-width: 1140px) {
  .footer {
    .footer-container {
      .card-footer {
        .col-4 {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .lorem {
          opacity: 0.7;
          font-size: 18px;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: right;
          color: #3e3e3e;
          max-width: 60%;
          margin-top: 15px;
        }
        h4 {
          font-size: 21px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.96;
          letter-spacing: normal;
          text-align: start;
          color: #434343;
          padding-bottom: 15px;
        }
        .wrap {
          display: flex;
          align-items: center;
          p {
            font-size: 19px;
            color: #3e3e3e;
            margin-bottom: 0;
            margin-right: 8px;
          }
          a {
            margin-bottom: 0;
            font-size: 19px;
            color: #3e3e3e;
            margin-right: 8px;
          }
        }
        .col-1,
        .col-2,
        .col-3,
        .col-4,
        .col-5 {
          padding: 0;
        }
      }
    }
  }
}
@media (max-width: 959px) {
  .footer {
    height: auto;
    .footer-container {
      .card-footer {
        min-height: auto;
        padding: 15px 30px;
        .col-4 {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .lorem {
          opacity: 0.7;
          font-size: 18px;
          line-height: 1.14;
          letter-spacing: normal;
          color: #3e3e3e;
          max-width: 100%;
          margin-top: 15px;
          padding-block: 20px;
          @media (min-width: 769px) and (max-width: 959px){
            padding-block: 0;
          }
          @media (min-width: 960px){
            padding-inline: 60px;
          }
          @media (max-width: 768px) {
            padding-inline: 20px;
          }
          @media (max-width: 700px) {
            padding-inline: 0;
          }
        }
        h4 {
          font-size: 20px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.96;
          letter-spacing: normal;
          text-align: right;
          color: #434343;
          padding-bottom: 15px;
          margin-inline-start: 7px;
        }
        .wrap {
          display: flex;
          align-items: center;
          p {
            font-size: 17px;
            color: #3e3e3e;
            margin-bottom: 0;
            margin-inline-start: 8px;
          }
          a {
            margin-bottom: 0;
            font-size: 17px;
            color: #3e3e3e;
            margin-inline-start: 8px;
          }
        }
        .col-1,
        .col-2,
        .col-3,
        .col-4,
        .col-5 {
          padding: 0;
        }
      }
    }
    .bottom-wrapper {
      width: 100%;
      padding-bottom: 50px;
      .col-2s {
        width: 100%;
        .sub-card {
          font-size: 20px;
          height: 4vh;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 0 25px;
          color: #939393;
        }
      }
      .b {
        border-inline: none;
      }
    }
  }
}
.social{
  display: flex; gap: 10px; margin-top: 10px;
}
</style>
