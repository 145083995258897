<template>
  <div class="page-wrap tasks-page">
    <loader style="z-index: 1;" v-if="loading"></loader>
    <div class="head task-head">
      <h1 class="head-title">{{ $t(`MY_TASKS_${user.role}`) }}</h1>
      <div class="mail-wrap" @click="feedbackList.length? openFeedbackList=!openFeedbackList: false">
        <span v-show="feedbackList.length" class="circle-for-feedback">{{feedbackList.length}}</span>
        <img src="@/assets/icons/toolmail.svg" alt=""/>
        <p>{{$t('FEEDBACK')}}</p>
      </div>

      <div class="feedback-box" v-if="openFeedbackList">
        <div class="feedback-item" v-for="(feedback,index) in feedbackList" @click="goToFeedback({feedback, index})">
          <span v-if="feedback.nameOfSendMailUser">{{feedback.nameOfSendMailUser}}</span>
          <span>{{feedback.nameUnit}}</span>
          <p>{{feedback.feedback}}</p>
        </div>
      </div>
    </div>
    <div v-if="user.isTeacher()" class="class-selected">
      <p>{{$t('CLASS')}}</p>
      <div class="select-wrap">
        <select v-model="selectedClass" name="classes" id="classes" class="select-class">
          <option
              :value="classItem.id"
              :key="index"
              v-for="(classItem, index) in classes">
            {{ classItem.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="task-container">
      <div v-if="getCorrectTasks.length" class="col-3-s card" v-for="(element, lessonIndex) in getCorrectTasks"
           :key="lessonIndex">
        <div class="label" v-if="element.assessment">
            <span class="text">
              {{$t('ASSESSMENT')}}
            </span>
        </div>
        <div class="task-item tasks"
             :class="{
          isTeacher: user.isTeacher(),
          disabled: !user.isTeacher() && element.isLockToAns === 2
        }"
             :title="getLessonTitle(element)">
          <a @click="user.isTeacher() || element.isLockToAns !== 2? goToLesson(element): false">
            <div :style="{ backgroundImage: 'url(' + element.image + ')' }" class="card-bg"></div>
            <div
                class="d-flex flex-column align-center content-wrapper"
                :class="{ teacher: user.isTeacher() }">
              <div v-if="element.grade != null && !user.isTeacher()" class="top-wrap">
                <p>{{getGrade(element.grade)}}</p>
              </div>
              <div class="bottom-wrap">
                <p class="name">{{ element.name }}</p>
                <div class="circle"
                     v-if="!user.isTeacher() && ( element.progress_percentage || element.progress_percentage === 0)"
                     :class="{ student: !user.isTeacher() }">
                  <circle-chart
                      :val="element.progress_percentage">
                  </circle-chart>
                </div>
              </div>
            </div>
          </a>
          <div v-if="user.isTeacher()" class="teacher-tool-bar">
            <div class="teacher-tool-bar-item delete d-flex flex-column">
              <img
                  src="@/assets/icons/deletetask.svg"
                  alt="delete"
                  @click="deleteTaskPopUp(element, lessonIndex)"
              />
              <div class="teacher-tool-bar-item-hover">
                <p>{{$t('DELETE_TASK')}}</p>
              </div>
            </div>
            <div class="teacher-tool-bar-item d-flex flex-row" @click="openLessonReport(element)">
              <img src="@/assets/icons/chart.svg" alt=""/>
              <div class="teacher-tool-bar-item-hover">
                <p>{{$t('REPORTS')}}</p>
              </div>
            </div>
            <div class="teacher-tool-bar-item d-flex flex-row">
              <img
                  :src="getIconByLessonStatus(element)"
                  alt=""
                  @click="toggleTool(lessonIndex)"
              />
              <div class="teacher-tool-bar-item-hover">
                <p>{{$t('LESSON_STATUS')}}</p>
              </div>
            </div>
            <div
                class="toolbar-dropdown"
                :class="{ active: dropDownIndex === lessonIndex }"
            >
              <div
                  class="toolbar-dropdown-item"
                  v-for="(el, index) in dropdown"
                  :key="index"
                  @click="el.onClick(element, lessonIndex)"
              >
                <img :src="el.icon"/>
                <p>{{ el.title }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="delete-task-popup" :class="{ active: toggleModalIndex === lessonIndex }">
          <div class="overlay"></div>
          <div class="delete-task-popup-container">
           <div class="close" @click="btnCancel">
                <img src="@/assets/icons/x.svg" alt="x" />
            </div>
            <p class="text">{{$t('DELETE_TASK_WARNING')}}</p>
            <div class="btns-wrap">
              <div class="btns-option">
                <button class="btn-delete" @click="deleteTask(element, lessonIndex)">
                  {{$t('OK')}}
                </button>
                <button @click="btnCancel" class="btn-cancel">{{$t('CANCEL')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!getCorrectTasks.length && !loading">
      <div v-if="user.isTeacher()" class="no-tasks">
        <h2 class="no-tasks-title">{{$t('TEACHER_EMPTY_TASKS_MSG')}}</h2>
        <p class="no-tasks-text">{{$t('TEACHER_EMPTY_TASKS_MSG_2')}}</p>
        <router-link class="no-tasks-url" :to="{name: 'content'}">{{$t('MOVE_TO_DIGITAL_BOOKS')}}</router-link>
      </div>
      <div class="no-tasks" v-else>
        <h2 class="no-tasks-title">{{$t('STUDENTS_EMPTY_TASKS_MSG')}}</h2>
        <img class="no-tasks-student-icon" src="@/assets/icons/student-astronaut.svg">
      </div>
    </div>
  </div>
</template>

<script>
  import CircleChart from '../../../components/CircleChart.vue'
  import { mapActions, mapState } from 'vuex'
  import helpers from '@/helpers'
  import Loader from '../../../components/Loader'
  import { TYPELESSON_ASSESSMENT } from '@/constants.js'

  export default {
    name: 'tasks',
    components: { Loader, CircleChart },
    data () {
      return {
        openFeedbackList: false,
        mail: require('@/assets/icons/envelope.svg'),
        tasks: [],
        loading: false,
        dropdown: [
          {
            title: this.$t('OPEN_LESSON'),
            icon: require('@/assets/icons/play.svg'),
            type: 'opentask',
            onClick: (task, lessonIndex) => this.updateStatus(task, 'open', lessonIndex),
          },
          {
            title: this.$t('LOCK_LESSON_ANSWERS'),
            icon: require('@/assets/icons/lock_answers_lesson.svg'),
            type: 'locktask',
            onClick: (task, lessonIndex) => this.updateStatus(task, 'lockAns', lessonIndex),
          },
          {
            title: this.$t('LOCK_LESSON_VIEW'),
            icon: require('@/assets/icons/lock_view_lesson.svg'),
            type: 'answers',
            onClick: (task, lessonIndex) => this.updateStatus(task, 'lockView', lessonIndex),
          },
          {
            title: this.$t('LOCK_LESSON'),
            icon: require('@/assets/icons/hide_lesson.svg'),
            type: 'lockwatchtask',
            onClick: (task, lessonIndex) => this.updateStatus(task, 'hide', lessonIndex),
          },
        ],
        dropDownIndex: false,
        DeleteTask: false,
        toggleModal: null,
        toggleModalIndex: null,
      }
    },
    created () {
      if (!this.selectedClass) {
        this.$store.dispatch('setSelectedClass', this.classes[0].id)
      }
      this.$store.dispatch('getFeedback')
      this.fetchData()
    },
    watch: {
      selectedClass () {
        this.fetchData()
      },
      selectedSchool () {
        this.$store.dispatch('setSelectedClass', this.classes[0].id)
      },
    },
    computed: {
      ...mapState({
        feedbackList: state => state.feedbackList,
        librarySlug: state => state.librarySlug
      }),
      selectedClass: {
        get () {
          return this.$store.state.selectedClass
        },
        set (value) {
          this.$store.dispatch('setSelectedClass', value)
        }
      },
      user () {
        return this.$auth.user
      },
      classes () {
        return this.$store.getters['classes']
      },
      selectedSchool () {
        return this.$store.getters['schoolIndex']
      },
      getCorrectTasks () {
        if (this.user.isTeacher()) {
          return this.tasks
        } else {
          return this.tasks.filter(t => t.publishType)
        }
      }
    },
    methods: {
      ...mapActions(['goToFeedback']),
      getGrade (grade) {
        return Math.round(grade)
      },
      goToLesson (lesson) {
        const baseUrl = helpers.env('LEGACY_CLASSE_HOST')
        const lang = this.librarySlug === 'ramot-heb' ? 'he' : 'arb'
        const url = baseUrl + '/teacher/#!/' + lang + '/session/' + lesson.unit_id + '/playLesson/' + lesson.id + '/'
        this.$store.dispatch('openEntryInNewWindow', url)
      },
      getLessonTitle (element) {
        if (this.user.isTeacher() || !element.isLockToAns) {
          return false
        }
        if (element.isLockToAns === 2) {
          return this.$t('LOCK_LESSON_ERR_MSG')
        } else {
          return this.$t('LOCK_LESSON_ANSWERS_ERR_MSG')
        }
      },   
      openLessonReport (lesson) {
        const baseUrl = helpers.env('LEGACY_CLASSE_HOST')
        const lang = this.librarySlug === 'ramot-heb' ? 'he' : 'arb'
        const url = baseUrl + '/teacher/#!/' + lang + '/session/containerReport/' + lesson.unit_id + '/' + lesson.id + '/gradeLessonReport/'
        this.$store.dispatch('openEntryInNewWindow', url)
      },
      getIconByLessonStatus (lesson) {
        if (!lesson.publishType) {
          return this.dropdown[3].icon
        } else {
          return this.dropdown[lesson.isLockToAns].icon
        }
      },
      deleteTaskPopUp (element, lessonIndex) {
        this.$scrollToTop()
        this.toggleModal = element
        this.toggleModalIndex = lessonIndex
      },
      btnCancel () {
        this.toggleModal = null
        this.toggleModalIndex = null
      },
      async deleteTask () {
        this.loading = true
        await this.$api.delete(`/lesson/${this.toggleModal.id}/`)
        this.tasks.splice(this.toggleModalIndex, 1)
        this.btnCancel()
        this.loading = false
      },
      async updateStatus (task, action, lessonIndex) {
        this.loading = true

        let body = {
          isLockToAns: 0,
          publishType: 0,
          class_id: this.selectedClass,
        }

        switch (action) {
          case 'open': {
            body.publishType = 1
            break
          }
          case 'lockAns': {
            body.publishType = 1
            body.isLockToAns = 1
            break
          }
          case 'lockView': {
            body.publishType = 1
            body.isLockToAns = 2
            break
          }
          case 'hide': {
            body.publishType = 0
            body.isLockToAns = 0
            break
          }
        }

        this.tasks[lessonIndex].publishType = body.publishType
        this.tasks[lessonIndex].isLockToAns = body.isLockToAns
        await this.$api.patch(`/lesson/${task.id}/update-ramot-lessons/`, body)
        this.toggleTool(false)
        this.loading = false

        this.toggleTool
      },
      async fetchData () {
        this.loading = true
        this.tasks = await this.user.fetchData(this.$route.name, {
          classId: this.selectedClass,
        })
        this.tasks = this.tasks.map(task => ({
          ...task,
          assessment: (task.typelesson.name == TYPELESSON_ASSESSMENT ) ? true : false
        }))
        this.loading = false
      },
      toggleTool (index) {
        if (this.dropDownIndex === index) {
          this.dropDownIndex = false
          return
        }
        this.dropDownIndex = index
      },
    },
  }
</script>
<style lang="scss">
  .page-wrap {
    transition: opacity 0.5s ease;
    padding: 0 10px;

    .head {
      &.task-head {
        display: flex;
      }

      &-title {
        font-size: 60px;
        text-align: center;
        color: #0f707b;
        width: 100%;
      }

      .feedback-box {
        width: 250px;
        position: absolute;
        background: #ffffff;
        transform: translateX(22%);
        margin-top: 15px;
        border: 1px solid #c9c8c8;
        box-shadow: 0 0 3px 1px rgb(150 147 150 / 44%);
        left: -35px;
        top: 115px;
        z-index: 2600;

        .feedback-item {
          cursor: pointer;
          padding: 10px;
          font-size: 14px;

          span {
            font-weight: 700;
          }
        }

        .feedback-item:hover {
          background: #eeebeb;
        }
      }

      .mail-wrap {
        .circle-for-feedback {
          background: red;
          width: 23px !important;
          height: 23px !important;
          border-radius: 20px;
          position: absolute;
          color: white;
          font-size: 14px;
          text-align: center;
          font-family: sans-serif;
          padding: 2px;
          transform: translate(40%, -3px);
        }

        img {
          width: 40px;
        }

        p {
          font-size: 18px;
          margin: 0 auto;
        }

        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 5px;
        row-gap: 0;
        margin-inline-start: auto;
      }

      @media (max-width: 959px) {
        margin-top: 60px;
      }
    }

    .class-selected {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: center;
      line-height: 1.1;

      .select-wrap {
        display: flex;
        align-items: center;
        gap: 8px;
        background: #efefef;
        font-size: 21px;
        border-radius: 5px;
        margin-bottom: 20px;
        @media (min-width: 600px) {
          padding: 5px 10px;
        }
        @media (max-width: 599px) {
          padding: 0 15px;
          outline: none;
        }
      }

      p {
        font-size: 40px;
        color: #504e4e;
        font-weight: 300;
        margin: 0 15px;
        @media (max-width: 599px) {
          font-size: 35px;
        }
        @media (max-width: 499px) {
          font-size: 25px;
        }
      }
    }

    .task-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 25px;
      position: relative;
      isolation: isolate;
      .card .label{
        display: flex; align-items: center; justify-content: center; padding-inline: 5px; text-align: center;
        position: absolute; z-index: 999; min-height: 70px; width: 90px; border: 1px solid #fff;
        border-top-right-radius: 10px; border-bottom-left-radius: 15px; background-color: #2D9199;
        border-top: none; border-right: none;
        .text{
          color: #fff; font-size: 16px; font-weight: bold; font-family: Arimo, sans-serif; line-height: 1; letter-spacing: 1px;
        }
      }
    }

    .delete-task-popup {
      position: absolute;
      align-items: center;
      justify-content: center;
      z-index: 2147483647;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 50%;
      right: 50%;
      bottom: 50%;
      transform: translate(50%, 20%);
      display: none;

      &.active {
        display: flex;
      }

      .overlay {
        background-color: #1E1E1EA1;
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: -20%;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }

      .delete-task-popup-container {
        background: white;
        border-radius: 25px;
        padding: 30px;
        position: relative;
        z-index: 2;
        box-shadow: 0 3px 6px #00000099;
        transform: translate(0, -80%);

        .close {
          position: absolute;
          inset-inline-end: 20px;
          top: 10px;
          display: flex;
          justify-content: flex-end;
          z-index: 99;
          padding: 10px 0;
        }

        .text {
          font-weight: 300;
          padding: 15px 0;
          text-align: center;
          font-size: 33px;
          line-height: 1.2;
          margin-top: 10px;
        }

        .btns-wrap {
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 20px 0;

          .btns-option {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            width: 340px;

            .btn-delete {
              width: 153px;
              height: 40px;
              background: #0f707b;
              cursor: pointer;
              border-radius: 30px;
              color: white;
              font-size: 18px;
            }

            .btn-cancel {
              width: 153px;
              height: 40px;
              background: white;
              border: 1px solid #0f707b;
              cursor: pointer;
              border-radius: 30px;
              color: #0f707b;
              font-size: 18px;
            }
          }
        }
      }
    }

    .col-3-s {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 10px;
      @media (max-width: 959px) {
        max-width: 50%;
        flex: 0 0 50%;
        padding: 10px;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 10px;
      }

      .task-item {
        display: flex;
        flex-direction: column;
        position: relative;
        cursor: pointer;
        border-radius: 10px;
        background-color: #fff;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &.isTeacher {
          filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.96));

          &.isTeacher {
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.96);
            border-radius: 10px;
          }

          img {
            width: 100%;
            height: 300px;
            display: block;
            object-fit: cover;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            @media (max-width: 959px) {
              width: 100%;
              height: 300px;
            }
          }

          .content-wrapper {
            border-top: 1px solid rgba(255, 255, 255, 0.322);
            background-image: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0),
                    rgba(0, 0, 0, 0.01) 47%,
                    #000000 100%
            );
            position: absolute;
            width: 100%;
            padding: 0 14px;
            top: 0;
            height: 100%;

            .bottom-wrap {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;
              height: 25%;
              padding-inline-end: 10px;
              margin-top: auto;
            }

            &.teacher {
              top: 0;
              height: 78%;
            }

            .name {
              font-size: 26px;
              font-weight: bold;
              line-height: 1;
              color: white;
              margin-bottom: 0;
            }

            @media (max-width: 1240px) {
              top: 0;
            }

            .col-8 {
              font-size: 30px;
              font-weight: bold;
              line-height: 1;
              color: white;
              margin-bottom: 0;

              p {
                margin-bottom: 0;
                line-height: 1.5rem;
              }
            }

            .mail {
              position: relative;
              margin-inline-start: auto;

              img {
                width: 40px;
                height: 40px;
                border-radius: 0;
                position: relative;
              }

              .val-point {
                position: absolute;
                top: 10%;
                left: 0;
                right: 5%;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                border: 2px solid white;
                background: red;
                display: flex;
                align-content: space-between;
                justify-content: space-around;
                @media (max-width: 1440px) {
                  width: 20px;
                  height: 20px;
                  margin-bottom: 0;
                  display: flex;
                  align-items: center;
                  font-size: 13px;
                }

                p {
                  color: white;
                  text-align: center;
                  margin-bottom: 0;
                  @media (max-width: 1240px) {
                    font-size: 13px;
                    margin-bottom: 0;
                  }
                }
              }
            }

            .app-circle {
              margin-inline-start: 15px;
            }

            .circle {
              .circle-procces {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                border: 2px solid red;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (max-width: 1440px) {
                  width: 35px;
                  height: 35px;
                }
                @media (max-width: 1240px) {
                  width: 30px;
                  height: 30px;
                }

                p {
                  color: white;
                  font-size: 18px;
                  margin-bottom: -2px;
                  @media (max-width: 1440px) {
                    margin-top: 3px;
                    font-size: 16px;
                    margin-bottom: 0;
                  }
                  @media (max-width: 1240px) {
                    font-size: 12px;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }

          .teacher-tool-bar {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 10px;
            position: relative;
            z-index: 1;
            height: 64px;

            .teacher-tool-bar-item {
              padding: 5px 10px;
              position: relative;

              img {
                width: 30px;
                height: 30px;
                object-fit: contain;
                cursor: pointer;
                display: block;
                margin: 0 auto;
              }

              &:nth-of-type(4) {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;

                p {
                  margin-bottom: 0;
                }
              }

              .teacher-tool-bar-item-hover {
                position: absolute;
                min-width: 80px;
                border: 1px solid #707070;
                top: 100%;
                color: white;
                background: #707070;
                right: -20px;
                border-radius: 15px;
                padding: 2px 10px;
                white-space: nowrap;
                display: none;

                p {
                  margin-bottom: 0;
                  text-align: center;
                }
              }

              &:hover {
                .teacher-tool-bar-item-hover {
                  display: block;
                }
              }
            }

            .toolbar-dropdown {
              position: absolute;
              width: 68%;
              top: 90%;
              left: 10px;
              box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.96);
              border-radius: 10px;
              z-index: 120;
              background: white;
              padding: 10px;
              display: none;

              &.active {
                display: block;
              }

              .toolbar-dropdown-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 5px 0;

                &:hover {
                  background: #dcfaff;
                }

                img {
                  width: 20px;
                  height: 20px;
                  object-fit: contain;
                }

                p {
                  margin: 0 8px;
                }
              }
            }
          }
        }

        .top-wrap {
          background-color: orange;
          color: white;
          border-radius: 50%;
          padding: 15px;
          font-size: 25px;
          position: inherit;
          left: 10px;
          width: 60px;
          height: 60px;
          text-align: center;
          display: flex;
          align-items: baseline;
          justify-content: center;
        }
      }

      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      ::-webkit-scrollbar-thumb {
        background: #0f707b;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #0f707b;
      }


      .triangle-down {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #707070;
      }

      select:focus {
        outline: none !important;
      }
    }
  }

  .no-tasks {
    max-width: 890px;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;

    &-title {
      color: #434242;
      font-weight: 500;
    }

    &-text {
      color: #707070;
      line-height: 1.1;
    }

    &-url {
      $height: 40px;
      background-color: #0691FB;
      border-radius: 24px;
      color: #fff !important;
      font-size: 1.8rem;
      font-weight: 600;
      width: max-content;
      height: $height;
      line-height: $height;
      padding: 0 20px;
      margin: auto;
    }

    &-student-icon {
      width: 130px;
      align-self: center;
    }

    @media all and (max-width: 1023px) {
      &-title {
        font-size: 2rem;
      }
      &-text {
        font-size: 1.9rem;
      }
    }
    @media all and (min-width: 1024px) {
      &-title {
        font-size: 2.5rem;
      }
      &-text {
        font-size: 2.3rem;
      }
    }
  }

  @for $i from 1 through 10000 {
    .card:nth-child(#{$i}) {
      .task-item {
        z-index: ((-$i + 1000) - 1);

        .toolbar-dropdown.active {
          z-index: $i + 10000;
        }
      }
    }
  }

  .circle.student {
    padding-inline-end: 10px;
    padding-bottom: 10px;
  }
</style>
