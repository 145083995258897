<template>
  <nav class="navbar">
    <template v-if="currentRoute === 'home'">
      <video autoplay muted loop>
        <source src="@/assets/media/vid.mp4" type="video/mp4"/>
      </video>
    </template>
    <div class="navbar-container">
      <div class="d-none d-md-flex align-center navbar-container-desk">
        <router-link style="display: flex" to="/">
          <div class="icon"><img :src="logoUrl" alt="logo"/></div>
        </router-link>
        <div class="main-logo">
          <img src="@/assets/icons/univ.svg" alt="univ" />
        </div>
        <div class="title">
          <router-link :to="{ name: 'About' }">
            <span class="title">{{ $t('ABOUT') }}</span>
          </router-link>
        </div>
        <div class="auth">
          <button @click="authModal" class="btn-signin">
            <span class="text">{{ $t('ENTER') }}</span>
          </button>
          <a class="hinuh-office"
             :class="{disable : hasIdmError}"
             :href="getIdmURL">
            <span class="text">{{ $t('MOE_SSO') }}</span>
            <span class="icon-owl" >
              <img src="@/assets/icons/owl.svg" alt="owl" />
            </span>
            <template v-if="hasIdmError">
              <p class="red-msg">בעקבות תקלה במשרד החינוך לא ניתן להתחבר עם ההזדהות. הנושא בטיפול</p>
              <p class="red-msg">بسبب عطل في وزارة التربية والتعليم لا يمكن الاتصال بالنظام. الموضوع قيد العلاج</p>
            </template>
          </a>
          <div @click="handleLang" class="lang">
            <img src="@/assets/icons/internet.svg" alt="language icon" class="lang-icon" />
            <p>{{ changeLangText }}</p>
            <div class="selected-lang">
              <p>ערבית</p>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex d-md-none navbar-mob d-flex flex-row align-center">
        <div class="mob-menu-wrapper d-flex flex-row">
          <div class="icon" >
           <img src="@/assets/icons/mabatfooter.svg" alt="mabatfooter" />
          </div>
          <div class="icon" >
          <img src="@/assets/icons/univfooter.svg" alt="univfooter" />
          </div>
          <div class="sidebar-mob" :class="{ active: toggleSideBar }">
            <div class="sidebar-mob-wrap" :class="{ active: toggleSideBar }">
              <div @click="authModal" class="btn-signin">
                <p>{{ $t('ENTER') }}</p>
              </div>
              <a class="hinuh-office" :href="getIdmURL">
                <span class="text">{{ $t('MOE_SSO') }}</span>
                <span class="icon-owl" >
                <img src="@/assets/icons/owl.svg" alt="owl" />
                </span>
              </a>
              <div class="title">
                <router-link :to="{ name: 'About' }">
                  <span class="title">{{ $t('ABOUT') }}</span>
                </router-link>
              </div>
              <div @click="handleLang" class="lang">
                <img src="@/assets/icons/internet.svg" alt="language icon" class="lang-icon" />
                <p>{{ changeLangText }}</p>
                <div class="selected-lang">
                  <p>ערבית</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div @click="toggleSideBarMob" class="mob-menu">
          <img v-if="toggleSideBar" src="@/assets/icons/cancel.png" alt=""/>
          <img
              v-if="!toggleSideBar"
              src="@/assets/icons/sidemobwhite.png"
              alt=""
          />
        </div>
      </div>

      <div v-if="currentRoute === 'home'" class="navbar-content">
        <div class="navbar-wrapper">
          <h1>{{ $t('SITE_NAME') }}</h1>
          <h2>{{ $t('SITE_TITLE_1') }}</h2>
          <h2>{{ $t('SITE_TITLE_2') }}</h2>
        </div>
      </div>
    </div>
    <div v-if="currentRoute === 'home'" class="bottom-wrapper">
      <div class="arrow">
        <div class="scroll-down">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="bottom-wrapper-container">
        <div v-html="bannerText"></div>
      </div>
      <div class="fake"></div>
    </div>
    <Modal v-if="toggleModal" :toggleModal="toggleModal" :authModal="authModal"/>
  </nav>
</template>

<script>
import Modal from '../components/Modal'
import store from '../store'
import { MUTATION_TYPES } from '../store/types'
import helpers from '@/helpers'
import { mapState } from 'vuex'

export default {
  name: 'app-navbar',
  components: { Modal },
  data () {
    return {
      toggleModal: false,
      changeLangText: null,
      toggleSideBar: false,
      windowHeight: window.innerHeight,
      currentRoute: this.$route.name,
    }
  },
  props: ['bannerText', 'logoUrl'],
  created () {
    if (this.currentRoute === 'joinToClass') {
      const url = this.getIdmURLFunc(this.$route.params.classId)
      location.href = url
    }
  },
  mounted () {
    if (this.librarySlug === 'ramot-heb') {
      this.changeLangText = 'العربية'
    } else {
      this.changeLangText = 'עברית'
    }
  },
  computed: {
    hasIdmError () {
      return helpers.env('HAS_IDM_ERROR') === 'true'
    },
    getIdmURL () {
      return helpers.getIdmURL()
    },
    ...mapState({
      librarySlug: state => state.librarySlug,
      isLoggedIn: state => state.isLoggedIn
    })
  },
  methods: {
    getIdmURLFunc (classId = null) {
      return helpers.getIdmURL(classId)
    },
    authModal () {
      this.toggleModal = !this.toggleModal
      if (this.toggleSideBar) {
        this.toggleSideBar = false
      }
    },
    handleLang () {
      // Replace library from `Heb` to `Arb` and vice versa
      if (this.changeLangText === 'עברית') {
        this.changeLangText = 'العربية'
        store.commit(MUTATION_TYPES.SET_LIBRARY_SLUG, 'ramot-heb')
        this.$router.push('/ramot-heb')
      } else {
        this.changeLangText = 'עברית'
        store.commit(MUTATION_TYPES.SET_LIBRARY_SLUG, 'ramot-arb')
        this.$router.push('/ramot-arb')
      }
    },
    toggleSideBarMob () {
      this.toggleSideBar = !this.toggleSideBar
    },
  },
}
</script>
<style lang="scss">
.red-msg{color: red; font-size: 16px; margin-top: 10px;}
.navbar {
  position: relative;

  video {
    width: 100%;
    position: relative;
  }

  .navbar-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    .navbar-container-desk {
      max-width: 1750px;
      margin: 0 auto;
      padding: 15px 0;
      margin-inline-start: 15px;

      .icon {
        min-width: 12%;
        text-align: left;
        padding-left: 15px;

        img {
          max-width: 124px;
          height: auto;
          display: block;
          margin: 0 auto;
        }
      }

      .icon:nth-of-type(2) {
        text-align: right;
        min-width: 8%;
      }

      span {
        font-family: "Almoni Neue DL 4.0 AAA", sans-serif !important;
      }

      .title {
        min-width: 14%;
        text-align: right;
        padding-right: 10px;

        a {
          text-decoration: none;
          font-size: 27px;
          font-weight: 400;
          line-height: 3.37;
          text-align: center;
          color: #1a7580;
        }
      }

      .auth {
        min-width: 64%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .btn-signin {
          object-fit: contain;
          border-radius: 21.5px;
          border: solid 1px #1a7580;

          &:hover {
            background-color: #1a7580;

            .text {color: white;}
          }

          .text {
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #1a7580;
            margin-bottom: 0;
            cursor: pointer;
            @media screen and (max-width: 1024px){
              font-size: 24px;
            }
            @media screen and (min-width: 1025px){
              font-size: 27px;
            }
          }
          @media screen and (max-width: 1024px){
            width: 120px; height: 37px;
          }
          @media screen and (min-width: 1025px){
            width: 128px; height: 43px;
          }
        }

        .hinuh-office {
          max-width: max-content;
          width: 100%;
          padding-inline-start: 15px;
          padding-inline-end: 50px;
          white-space: nowrap;
          border-radius: 21.5px;
          border: solid 1px #1a7580;
          margin: 0 15px;
          cursor: pointer;
          position: relative;


        &.disable{
             pointer-events: none; opacity: 0.6;
          }
          &:hover {
            background: #1a7580;
            border: 1px solid #1a7580;
            transition: ease;
            .text {color: white;}
          }

          .text {
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #1a7580;
            @media screen and (max-width: 1024px){
              font-size: 24px;
            }
            @media screen and (min-width: 1025px){
              font-size: 27px;
            }
          }

          .icon-owl {
            position: absolute;
            top: -60%;
            left: 6px;
          }
          @media screen and (max-width: 1024px){
            height: 37px;
          }
          @media screen and (min-width: 1025px){
            height: 43px;
          }
        }

        .lang {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 15px;
          position: relative;
          cursor: pointer;

          svg {
            width: 27px;
            height: 27px;
            margin: 0 5px;
          }

          p {
            font-size: 27px;
            color: #1a7580;
            margin-bottom: 6px;
          }

          .selected-lang {
            opacity: 0;
            background: white;
            position: absolute;
            top: 100%;
            left: 0;
            width: 80%;
            text-align: left;
            padding-left: 15px;
            z-index: -1;

            &.active {
              opacity: 1;
              z-index: 3;
            }
          }
        }
      }
    }

    .navbar-content {
      height: 55%;
      display: flex;
      align-items: center;
      @media (max-width: 1240px) {
        height: 50%;
      }

      .navbar-wrapper {
        padding: 0 125px;
        -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        @media (max-width: 1450px) {
        }
        @media (max-width: 1240px) {
          padding: 0 75px;
        }

        h1 {
          font-size: 99px;
          font-weight: bold;
          color: white;
          text-shadow: 3px 3px 5px rgba(131, 131, 133, 1);
        }

        h2 {
          font-size: 75px;
          font-weight: 500;
          color: white;
          line-height: 4.2rem;
          text-shadow: 3px 3px 5px rgba(131, 131, 133, 1);
        }
      }

      @-webkit-keyframes slide-in-right {
        0% {
          -webkit-transform: translateX(1000px);
          transform: translateX(1000px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
          transform: translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-right {
        0% {
          -webkit-transform: translateX(1000px);
          transform: translateX(1000px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    .navbar-mob {
      position: fixed;
      width: 100%;
      left: 0;
      border: 1px solid #469ca4;
      background: #469ca4;
      z-index: 999999;
      justify-content: center;

      .mob-menu-wrapper {
        align-items: center;
        justify-content: center;
        width: 100%;

        .sidebar-mob {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          width: 0;
          height: 100vh;
          background: #0000005e;
          transition: ease 0.5s;

          &.active {
            width: 100%;
            transition: ease 0.5s;
          }

          .sidebar-mob-wrap {
            position: absolute;
            top: 0;
            right: -1px;
            width: 0;
            height: 100%;
            background: white;
            transition: ease 0.3s;
            display: none;

            &.active {
              width: 320px;
              transition: ease 0.3s;
              display: block;

              .btn-signin {
                width: 100%;
                padding: 10px 15px;
                object-fit: contain;
                border-radius: 0;
                border-bottom: solid 1px #1a7580;

                p {
                  font-size: 23px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  text-align: right;
                  color: #1a7580;
                  margin-bottom: 0;
                  cursor: pointer;
                }
              }

              .hinuh-office {
                display: flex; flex-direction: row-reverse; justify-content: flex-end; align-items: baseline;
                width: 100%;
                padding: 10px 15px;
                object-fit: contain;
                border-radius: 0;
                border-bottom: solid 1px #1a7580;
                cursor: pointer;
                position: relative;

                p {
                  font-size: 23px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  text-align: right;
                  color: #1a7580;
                  margin-bottom: 0;
                }

              /*  .icon-owl {
                  position: absolute;
                  top: -28%;
                  left: 25px;
                }*/
              }

              .title {
                width: 100%;
                padding: 10px 8px;
                border-bottom: 1px solid #1a7580;

                a {
                  text-decoration: none;
                  border-bottom: 1px solid transparent;
                  font-size: 23px;
                  font-weight: bold;
                  line-height: 0;
                  text-align: right;
                  color: #1a7580;

                  span {
                    font-weight: bold;
                    border-bottom: 1px solid transparent;
                  }
                }
              }

              .lang {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 12px;
                position: relative;
                cursor: pointer;
                border-bottom: 1px solid #1a7580 !important;

                svg {
                  width: 27px;
                  height: 27px;
                  margin: 0 5px;
                }

                p {
                  font-size: 27px;
                  color: #1a7580;
                  margin-bottom: 6px;
                }

                .selected-lang {
                  opacity: 0;
                  background: white;
                  position: absolute;
                  top: 100%;
                  left: 0;
                  width: 80%;
                  text-align: left;
                  padding-left: 15px;
                  z-index: -1;

                  &.active {
                    opacity: 1;
                    z-index: 3;
                  }
                }
              }
            }
          }
        }

        img {
          max-width: 100%;
          height: 45px;
        }
      }

      .mob-menu {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 762px) {
          width: 13%;
        }
        @media (max-width: 562px) {
          width: 16%;
        }

        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .bottom-wrapper {
    .arrow {
      display: flex;
      justify-content: center;
      position: relative;

      .scroll-down {
        position: absolute;
        top: -270px;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      @media (max-width: 1440px) {
        top: 50px;
      }
      @media (max-width: 1240px) {
        top: 100px;
      }

      .scroll-down span:nth-child(2) {
        animation-delay: -0.2s;
      }

      .scroll-down span:nth-child(3) {
        animation-delay: -0.4s;
      }

      .scroll-down span {
        display: block;
        width: 20px;
        height: 20px;
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
        transform: rotate(45deg);
        margin: -10px;
        animation: animate 2s infinite;
      }

      @keyframes animate {
        0% {
          opacity: 0;
          transform: rotate(45deg) translate(-20px, -20px);
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: rotate(45deg) translate(20px, 20px);
        }
      }
    }

    .bottom-wrapper-container {
      background: #fdcb58;
      position: absolute;
      top: 82%;
      width: 100%;
      height: 13vh;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99;
      @media screen and (max-width: 768px){
        height: 24vh;
        span{font-size: 18px!important; display: block; text-align: center;}
      }
    }

    .fake {
      position: absolute;
      top: 89%;
      width: 100%;
      height: 15vh;
      background-image: linear-gradient(
              to bottom,
              rgba(248, 251, 253, 0),
              #eff6fb 124%
      );

      background: #eff6fb;
    }
  }
}

@media (max-width: 1024px) {
  .navbar {
    position: relative;
    max-height: 104vh;

    video {
      width: 100%;
      position: relative;
    }

    .navbar-container {
      .navbar-container-desk {
        .icon {
          min-width: 12%;
          text-align: center;

          img {
            max-width: 100px;
          }
        }

        .title {
          min-width: 8%;
          text-align: center;

          a {
            text-decoration: none;
            font-size: 27px;
            font-weight: bold;
            line-height: 3.37;
            text-align: center;
            color: #1a7580;
          }
        }

        .auth {
          min-width: 64%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .lang {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 15px;

            svg {
              width: 27px;
              height: 27px;
              margin: 0 15px;
            }

            p {
              font-size: 27px;
              color: #1a7580;
              margin-bottom: 0;
            }
          }
        }
      }

      .navbar-content {
        height: 50%;
        display: flex;
        align-items: center;

        .navbar-wrapper {
          padding: 0 20px;

          h1 {
            font-size: 80px;
            font-weight: bold;
            color: white;
            text-shadow: 3px 3px 5px rgba(131, 131, 133, 1);
          }

          h2 {
            font-size: 60px;
            font-weight: 500;
            color: white;
            line-height: 1;
          }
        }
      }
    }

    .bottom-wrapper {
      .arrow {
        display: flex;
        justify-content: center;

        .scroll-down {
          position: absolute;
          top: -254px;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .scroll-down span:nth-child(2) {
          animation-delay: -0.2s;
        }

        .scroll-down span:nth-child(3) {
          animation-delay: -0.4s;
        }

        .scroll-down span {
          display: block;
          width: 20px;
          height: 20px;
          border-bottom: 3px solid #fff;
          border-right: 3px solid #fff;
          transform: rotate(45deg);
          margin: -10px;
          animation: animate 2s infinite;
        }

        @keyframes animate {
          0% {
            opacity: 0;
            transform: rotate(45deg) translate(-20px, -20px);
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: rotate(45deg) translate(20px, 20px);
          }
        }
      }

      .bottom-wrapper-container {
        h3 {
          width: 650px;
          font-size: 35px;
        }
      }
    }
  }
}

@media (max-width: 959px) {
  .navbar {
    position: relative;
    max-height: 100%;

    video {
      width: 100%;
      position: relative;
      object-fit: cover;
      height: 115vh;
    }

    .navbar-container {
      .navbar-container-desk {
        .icon {
          min-width: 12%;
          text-align: center;

          img {
            max-width: 124px;
            height: auto;
            display: block;
            margin: 0 auto;
          }
        }

        .title {
          min-width: 8%;
          text-align: center;

          a {
            text-decoration: none;
            font-size: 27px;
            font-weight: bold;
            line-height: 3.37;
            text-align: center;
            color: #1a7580;
          }
        }

        .auth {
          min-width: 64%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .btn-signin {
            width: 128px;
            height: 43px;
            object-fit: contain;
            border-radius: 21.5px;
            background-color: #1a7580;

            p {
              font-size: 27px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: center;
              color: white;
            }
          }

          .hinuh-office {
            width: 337px;
            height: 43px;
            object-fit: contain;
            border-radius: 21.5px;
            border: solid 1px #1a7580;
            margin: 0 15px;

            p {
              font-size: 27px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: center;
              color: #1a7580;
            }

            .icon-owl {
              border: 1px solid red;
            }
          }

          .lang {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 15px;

            svg {
              width: 27px;
              height: 27px;
              margin: 0 15px;
            }

            p {
              font-size: 27px;
              color: #1a7580;
              margin-bottom: 0;
            }
          }
        }
      }

      .navbar-content {
        height: 65%;
        display: flex;
        align-items: center;

        .navbar-wrapper {
          padding: 0 50px;

          h1 {
            font-size: 70px;
            font-weight: bold;
            color: white;
            text-shadow: 3px 3px 5px rgba(131, 131, 133, 1);
          }

          h2 {
            font-size: 50px;
            font-weight: 500;
            color: white;
            line-height: 1;
          }
        }
      }
    }

    .bottom-wrapper {
      .arrow {
        display: flex;
        justify-content: center;

        .scroll-down {
          position: absolute;
          top: -150px;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        @media (max-width: 1440px) {
          top: -95px;
        }

        .scroll-down span:nth-child(2) {
          animation-delay: -0.2s;
        }

        .scroll-down span:nth-child(3) {
          animation-delay: -0.4s;
        }

        .scroll-down span {
          display: block;
          width: 20px;
          height: 20px;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg);
          margin: -10px;
          animation: animate 2s infinite;
        }

        @keyframes animate {
          0% {
            opacity: 0;
            transform: rotate(45deg) translate(-20px, -20px);
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: rotate(45deg) translate(20px, 20px);
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    position: relative;
    max-height: 100%;

    video {
      width: 100%;
      position: relative;
      object-fit: cover;
      height: 115vh;
    }

    .navbar-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;

      .navbar-container-desk {
        max-width: 1750px;
        margin: 0 auto;
        padding: 15px 0;
        margin-inline-start: 15px;

        .icon {
          min-width: 12%;
          text-align: center;

          img {
            max-width: 124px;
            height: auto;
            display: block;
            margin: 0 auto;
          }
        }

        .title {
          min-width: 8%;
          text-align: center;

          a {
            text-decoration: none;
            font-size: 27px;
            font-weight: bold;
            line-height: 3.37;
            text-align: center;
            color: #1a7580;
          }
        }

        .auth {
          min-width: 64%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .btn-signin {
            width: 128px;
            height: 43px;
            object-fit: contain;
            border-radius: 21.5px;
            background-color: #1a7580;

            p {
              font-size: 27px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: center;
              color: white;
            }
          }

          .lang {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 15px;

            svg {
              width: 27px;
              height: 27px;
              margin: 0 15px;
            }

            p {
              font-size: 27px;
              color: #1a7580;
              margin-bottom: 0;
            }
          }
        }
      }

      .navbar-content {
        height: 65%;
        display: flex;
        align-items: center;

        .navbar-wrapper {
          padding: 0px 50px;

          h1 {
            font-size: 75px;
            font-weight: bold;
            color: white;
            text-shadow: 3px 3px 5px rgba(131, 131, 133, 1);
          }

          h2 {
            font-size: 50px;
            font-weight: 500;
            color: white;
            line-height: 1;
          }
        }
      }
    }

    .bottom-wrapper {
      .arrow {
        display: flex;
        justify-content: center;

        .scroll-down {
          position: absolute;
          top: -190px;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        @media (max-width: 1440px) {
          top: -95px;
        }

        .scroll-down span:nth-child(2) {
          animation-delay: -0.2s;
        }

        .scroll-down span:nth-child(3) {
          animation-delay: -0.4s;
        }

        .scroll-down span {
          display: block;
          width: 20px;
          height: 20px;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg);
          margin: -10px;
          animation: animate 2s infinite;
        }

        @keyframes animate {
          0% {
            opacity: 0;
            transform: rotate(45deg) translate(-20px, -20px);
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: rotate(45deg) translate(20px, 20px);
          }
        }
      }
    }
  }
}

@media (max-width: 678px) {
  .navbar {
    position: relative;
    max-height: 100%;

    video {
      width: 100%;
      position: relative;
      object-fit: cover;
      height: 115vh;
    }

    .navbar-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;

      .navbar-container-desk {
        max-width: 1750px;
        margin: 0 auto;
        padding: 15px 0;
        margin-right: 15px;

        .icon {
          min-width: 12%;
          text-align: center;
        }

        .title {
          min-width: 8%;
          text-align: center;

          a {
            text-decoration: none;
            font-size: 27px;
            font-weight: bold;
            line-height: 3.37;
            text-align: center;
            color: #1a7580;
          }
        }

        .auth {
          min-width: 64%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .btn-signin {
            width: 128px;
            height: 43px;
            object-fit: contain;
            border-radius: 21.5px;
            background-color: #1a7580;

            p {
              font-size: 27px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: center;
              color: white;
            }
          }

          .lang {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 15px;

            svg {
              width: 27px;
              height: 27px;
              margin: 0 15px;
            }

            p {
              font-size: 27px;
              color: #1a7580;
              margin-bottom: 0px;
            }
          }
        }
      }

      .navbar-content {
        height: 65%;
        display: flex;
        align-items: center;

        .navbar-wrapper {
          padding: 0px 20px;

          h1 {
            font-size: 70px;
            font-weight: bold;
            color: white;
            text-shadow: 3px 3px 5px rgba(131, 131, 133, 1);
          }

          h2 {
            font-size: 40px;
            font-weight: 500;
            color: white;
            line-height: 1;
          }
        }
      }
    }

    .bottom-wrapper {
      .arrow {
        display: flex;
        justify-content: center;

        .scroll-down {
          position: absolute;
          top: -200px;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        @media (max-width: 1440px) {
          top: -95px;
        }

        .scroll-down span:nth-child(2) {
          animation-delay: -0.2s;
        }

        .scroll-down span:nth-child(3) {
          animation-delay: -0.4s;
        }

        .scroll-down span {
          display: block;
          width: 20px;
          height: 20px;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg);
          margin: -10px;
          animation: animate 2s infinite;
        }

        @keyframes animate {
          0% {
            opacity: 0;
            transform: rotate(45deg) translate(-20px, -20px);
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: rotate(45deg) translate(20px, 20px);
          }
        }
      }

    }
  }
}

@media (max-width: 545px) {
  .navbar {
    position: relative;
    max-height: 100%;

    video {
      width: 100%;
      max-height: 100%;
      position: relative;
      height: 109vh;
    }

    .navbar-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;

      .navbar-container-desk {
        max-width: 1750px;
        margin: 0 auto;
        padding: 15px 0;
        margin-right: 15px;

        .icon {
          min-width: 12%;
          text-align: center;

          img {
            max-width: 124px;
            height: auto;
            display: block;
            margin: 0 auto;
          }
        }

        .title {
          min-width: 8%;
          text-align: center;

          a {
            text-decoration: none;
            font-size: 27px;
            font-weight: bold;
            line-height: 3.37;
            text-align: center;
            color: #1a7580;
          }
        }

        .auth {
          min-width: 64%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .btn-signin {
            width: 128px;
            height: 43px;
            object-fit: contain;
            border-radius: 21.5px;
            background-color: #1a7580;

            p {
              font-size: 27px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: center;
              color: white;
            }
          }

          .lang {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 15px;

            svg {
              width: 27px;
              height: 27px;
              margin: 0 15px;
            }

            p {
              font-size: 27px;
              color: #1a7580;
              margin-bottom: 0px;
            }
          }
        }
      }

      .navbar-content {
        height: 65%;
        display: flex;
        align-items: center;

        .navbar-wrapper {
          padding: 0px 20px;

          h1 {
            font-size: 70px;
            font-weight: bold;
            color: white;
            text-shadow: 3px 3px 5px rgba(131, 131, 133, 1);
          }

          h2 {
            font-size: 40px;
            font-weight: 500;
            color: white;
            line-height: 1;
          }
        }
      }
    }

    .bottom-wrapper {
      .arrow {
        display: flex;
        justify-content: center;

        .scroll-down {
          position: absolute;
          top: -245px;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .scroll-down span:nth-child(2) {
          animation-delay: -0.2s;
        }

        .scroll-down span:nth-child(3) {
          animation-delay: -0.4s;
        }

        .scroll-down span {
          display: block;
          width: 20px;
          height: 20px;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg);
          margin: -10px;
          animation: animate 2s infinite;
        }

        @keyframes animate {
          0% {
            opacity: 0;
            transform: rotate(45deg) translate(-20px, -20px);
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: rotate(45deg) translate(20px, 20px);
          }
        }
      }

      .bottom-wrapper-container {
        h3 {
          width: 100%;
          font-size: 20px;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 495px) {
  .navbar {
    position: relative;
    max-height: 100%;

    video {
      width: 100%;
      max-height: 100%;
      position: relative;
    }

    .navbar-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;

      .navbar-container-desk {
        max-width: 1750px;
        margin: 0 auto;
        padding: 15px 0;
        margin-right: 15px;

        .icon {
          min-width: 12%;
          text-align: center;

          img {
            max-width: 124px;
            height: auto;
            display: block;
            margin: 0 auto;
          }
        }

        .title {
          min-width: 8%;
          text-align: center;

          a {
            text-decoration: none;
            font-size: 27px;
            font-weight: bold;
            line-height: 3.37;
            text-align: center;
            color: #1a7580;
          }
        }

        .auth {
          min-width: 64%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .btn-signin {
            width: 128px;
            height: 43px;
            object-fit: contain;
            border-radius: 21.5px;
            background-color: #1a7580;

            p {
              font-size: 27px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: center;
              color: white;
            }
          }

          .lang {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 15px;

            svg {
              width: 27px;
              height: 27px;
              margin: 0 15px;
            }

            p {
              font-size: 27px;
              color: #1a7580;
              margin-bottom: 0px;
            }
          }
        }
      }

      .navbar-content {
        height: 55%;
        display: flex;
        align-items: center;

        .navbar-wrapper {
          padding: 0px 20px;

          h1 {
            font-size: 50px;
            font-weight: bold;
            color: white;
            text-shadow: 3px 3px 5px #838385;
          }

          h2 {
            font-size: 33px;
            font-weight: 500;
            color: white;
            line-height: 1;
          }
        }
      }
    }

    .bottom-wrapper {
      .arrow {
        display: flex;
        justify-content: center;

        .scroll-down {
          position: absolute;
          top: -245px;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .scroll-down span:nth-child(2) {
          animation-delay: -0.2s;
        }

        .scroll-down span:nth-child(3) {
          animation-delay: -0.4s;
        }

        .scroll-down span {
          display: block;
          width: 20px;
          height: 20px;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg);
          margin: -10px;
          animation: animate 2s infinite;
        }

        @keyframes animate {
          0% {
            opacity: 0;
            transform: rotate(45deg) translate(-20px, -20px);
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: rotate(45deg) translate(20px, 20px);
          }
        }
      }

      .bottom-wrapper-container {
        h3 {
          width: 100%;
          font-size: 20px;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .navbar {
    position: relative;
    max-height: 100%;

    video {
      width: 100%;
      max-height: 100%;
      position: relative;
    }

    .navbar-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;

      .navbar-container-desk {
        max-width: 1750px;
        margin: 0 auto;
        padding: 15px 0;
        margin-right: 15px;

        .icon {
          min-width: 12%;
          text-align: center;

          img {
            max-width: 124px;
            height: auto;
            display: block;
            margin: 0 auto;
          }
        }

        .title {
          min-width: 8%;
          text-align: center;

          a {
            text-decoration: none;
            font-size: 27px;
            font-weight: bold;
            line-height: 3.37;
            text-align: center;
            color: #1a7580;
          }
        }

        .auth {
          min-width: 64%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .btn-signin {
            width: 128px;
            height: 43px;
            object-fit: contain;
            border-radius: 21.5px;
            background-color: #1a7580;

            p {
              font-size: 27px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: center;
              color: white;
            }
          }

          .lang {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 15px;

            svg {
              width: 27px;
              height: 27px;
              margin: 0 15px;
            }

            p {
              font-size: 27px;
              color: #1a7580;
              margin-bottom: 0px;
            }
          }
        }
      }

      .navbar-content {
        height: 55%;
        display: flex;
        align-items: center;

        .navbar-wrapper {
          padding: 0px 20px;

          h1 {
            font-size: 50px;
            font-weight: bold;
            color: white;
            text-shadow: 3px 3px 5px #838385;
          }

          h2 {
            font-size: 33px;
            font-weight: 500;
            color: white;
            line-height: 1;
          }
        }
      }
    }

    .bottom-wrapper {
      .arrow {
        display: flex;
        justify-content: center;

        .scroll-down {
          position: absolute;
          top: -150px;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .scroll-down span:nth-child(2) {
          animation-delay: -0.2s;
        }

        .scroll-down span:nth-child(3) {
          animation-delay: -0.4s;
        }

        .scroll-down span {
          display: block;
          width: 20px;
          height: 20px;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg);
          margin: -10px;
          animation: animate 2s infinite;
        }

        @keyframes animate {
          0% {
            opacity: 0;
            transform: rotate(45deg) translate(-20px, -20px);
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: rotate(45deg) translate(20px, 20px);
          }
        }
      }

      .bottom-wrapper-container {
        h3 {
          width: 100%;
          font-size: 20px;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 385px) {
  .navbar {
    position: relative;
    max-height: 100%;

    video {
      width: 100%;
      max-height: 100%;
      position: relative;
    }

    .navbar-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;

      .navbar-container-desk {
        max-width: 1750px;
        margin: 0 auto;
        padding: 15px 0;
        margin-right: 15px;

        .icon {
          min-width: 12%;
          text-align: center;

          img {
            max-width: 124px;
            height: auto;
            display: block;
            margin: 0 auto;
          }
        }

        .title {
          min-width: 8%;
          text-align: center;

          a {
            text-decoration: none;
            font-size: 27px;
            font-weight: bold;
            line-height: 3.37;
            text-align: center;
            color: #1a7580;
          }
        }

        .auth {
          min-width: 64%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .btn-signin {
            width: 128px;
            height: 43px;
            object-fit: contain;
            border-radius: 21.5px;
            background-color: #1a7580;

            p {
              font-size: 27px;
              font-weight: 900;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: center;
              color: white;
            }
          }

          .lang {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 15px;

            svg {
              width: 27px;
              height: 27px;
              margin: 0 15px;
            }

            p {
              font-size: 27px;
              color: #1a7580;
              margin-bottom: 0;
            }
          }
        }
      }

      .navbar-content {
        height: 55%;
        display: flex;
        align-items: center;

        .navbar-wrapper {
          padding: 0px 20px;

          h1 {
            font-size: 50px;
            font-weight: bold;
            color: white;
            text-shadow: 3px 3px 5px #838385;
          }

          h2 {
            font-size: 33px;
            font-weight: 500;
            color: white;
            line-height: 1;
          }
        }
      }
    }

    .bottom-wrapper {
      .arrow {
        display: flex;
        justify-content: center;

        .scroll-down {
          position: absolute;
          top: -150px;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .scroll-down span:nth-child(2) {
          animation-delay: -0.2s;
        }

        .scroll-down span:nth-child(3) {
          animation-delay: -0.4s;
        }

        .scroll-down span {
          display: block;
          width: 20px;
          height: 20px;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg);
          margin: -10px;
          animation: animate 2s infinite;
        }

        @keyframes animate {
          0% {
            opacity: 0;
            transform: rotate(45deg) translate(-20px, -20px);
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: rotate(45deg) translate(20px, 20px);
          }
        }
      }

      .bottom-wrapper-container {
        h3 {
          width: 100%;
          font-size: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>
