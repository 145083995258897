<template>
  <div class="videos-page">
    <div class="navabout" v-if="!isLoggedIn">
      <Navbar :logoUrl="logoUrl"/>
    </div>
    <header class="head">
      <h1 class="header-title">{{$t('INSTRUCTIONAL_VIDEOS')}}</h1>
    </header>
    <ul class="videos-list">
      <li class="videos-list-item" v-for="video in videos">
        <figure class="video-card">
          <iframe class="video-frame" :src="video.url" allowfullscreen></iframe>
          <figcaption class="video-caption">
            {{video.title}}
          </figcaption>
        </figure>
      </li>
    </ul>
    <Footer v-if="!isLoggedIn"/>
  </div>
</template>

<script>
  import Footer from '@/components/Footer'
  import Navbar from '@/components/Navbar.vue'
  import { mapState } from 'vuex'

  export default {
    name: 'videos',
    components: { Footer, Navbar },

    data () {
      return {}
    },

    beforeCreate () {
      this.$store.dispatch('getVideos')
    },

    computed: {
      ...mapState({
        isLoggedIn: state => state.isLoggedIn,
        videos: state => state.videos,
        logoUrl: state => state.logoUrl
      })
    }
  }
</script>
<style scoped lang="scss">
  .head{
    width: 100%; text-align: center;
    .header-title{
      color: #0F707B; font-size: 60px; font-weight: bold; margin-top:20px; margin-bottom: 25px;
    }
  }
  .navabout {
    background: #dcfaff;
    height: 15vh !important;
  }
  .videos-list{
    list-style: none; display: grid; gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr)); grid-template-rows: repeat(auto-fit, minmax(170px, 1fr));
    margin-inline-start: 20px;
   }
  .video-card{
    height: unset!important;
  }
  .video-frame{
    max-width: 390px; width: 100%; height:220px; border-radius: 10px;
  }
  .video-caption{
    color: #434242; font-size: 20px;
  }
</style>
