export function getLoginErrorMessage (schoolData) {
  let loginErrorMessage
  if(!Object.keys(schoolData.data.schools).length) {
    if (schoolData.data.is_teacher){
      loginErrorMessage = 'no-teacher-info' 
      } else loginErrorMessage = 'generalError' 
  } else if(Object.values(schoolData.data.schools)[0]){
    if (schoolData.data.is_teacher){
      loginErrorMessage = 'no-school-info' 
    } else loginErrorMessage = 'general-error' 
  } else {
    loginErrorMessage =  'not-purchased-error'
  }
  return loginErrorMessage
}