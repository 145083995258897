<template>
  <div class="app-circle">
    <vue-circle
      :progress="val"
      :size="45"
      :reverse="false"
      line-cap="round"
      :fill="fill"
      empty-fill="#ffffff"
      :animation-start-value="0.0"
      :start-angle="0"
      insert-mode="append"
      :thickness="6"
      :show-percent="false"
    >
    </vue-circle>
  </div>
</template>
 
<script>
import VueCircle from "vue2-circle-progress";
export default {
  props:['val'],
  components: {
    VueCircle,
  },
  data() {
    return {
      fill: "#FFC403",
    };
  },
};
</script>
<style lang="scss">
.app-circle{
  .circle{
    margin-top: 5px;
    .circle-percent-text-body{
      
      span{
        color: white;
        font-size: 16px !important;
        margin-top: -5px !important;
        font-weight: 300 !important;
      }
    }
  }
}
</style>