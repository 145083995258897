<template>
  <div class="error-message-container">
      <h2 class="title-modal semi-bold">{{$t('ERROR_LOGIN_TITLE')}}</h2>
      <p class="text">{{$t('NO_TEACHER_INFO_ERROR_TEXT')}}</p>
    <div class="support">
      <p class="support-text">{{$t('TO_SUPPORT')}}:</p>
      <a href="tel://052-5665706" class="tel">052-5665706</a>
      <span> | </span>
      <a href="mailto:support@classe.world" class="link">support@classe.world</a>
    </div>   
  </div>
</template>

<script>

  export default {
    name: 'noTeacherInfo',
    data () {
      return {}
    }
  }
</script>
<style lang="scss" scoped>
@use "src/scss/0-base/fonts" as *;
  .support{
    margin-top: .9rem; font-size: 20px; font-family: $font-assistant;
    &-text{font-weight: 400; line-height: 1.4; margin-bottom: unset;}
    .link{font-size: inherit;}
  }
</style>
