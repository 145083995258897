<template>
  <div class="modal active-desc">
    <article class="modal-container">
      <section class="description">
        <router-view></router-view>
      </section>
      <img src="@/assets/media/computer.png" alt="computer image" class="computer-image">
      <div class="head-modal">
        <div
            @click="closePopup"
            class="close"
           >
            <img src="@/assets/icons/closewhite.svg" alt="closewhite" />
        </div>
      </div>
    </article>
  </div>
</template>

<script>

  export default {
    name: 'LoginError',
    data () {
      return {}
    },
    methods: {
      closePopup () {
        this.$router.push({ name: 'home' })
      }
    }
  }
</script>
<style lang="scss">
@use "src/scss/0-base/fonts" as *;
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: #0000005e;
    align-items: center;
    justify-content: center;
    display: none;

    &.active-desc {
      display: flex;
      z-index: 999;
    }

    @keyframes slide-top {
      0% {
        transform: translateY(-500px);
      }
      100% {
        transform: translateY(0px);
      }
    }

    .modal-container {
      display: flex; align-items: center;
      background: white;
      border-radius: 25px;
      -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      .close {
        padding: 8px 0;
        width: min-content;
        height: min-content;
        float: left;
        margin: 10px;
        cursor: pointer;

        svg {
          width: 15px;
          height: 15px;
          margin-top: 8px;
        }
      }
      .link{text-decoration: underline; font-size: 20px; color: #007FFF;}
      .tel{color: #3b3b3b;}
      .description {
        width: 600px;
        height: 25vh;

        .wrap {
          display: flex;
          align-items: center;

          p {
            font-size: 23px;
            color: #3e3e3e;
            margin-bottom: 0;
            margin-inline-start: 8px;
          }

          a {
            margin-bottom: 0;
            font-size: 23px;
            color: #3e3e3e;
            margin-right: 8px;
          }
        }
      }
    }

    @-webkit-keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }
    @keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }

    .modal-container {
      height: 70%;
      background: white;
      border-radius: 30px;
      @media all and (min-width: 960px) {
        width: 70%;
      }

      .close {
        img {
          width: 15px;
          height: 15px;
        }
      }

      .head-modal {
        background: #1ab2c4;
        padding: 0 20px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: unset!important;
        top: 0; bottom: 0;
        position: absolute;
        inset-inline-end: 0;
        width: 22%;

        .close {
          svg {
            width: 20px;
            height: 20px;
            margin-top: 8px;
          }
        }
      }

      .description {
        width: 73%;
        height: 100%;
        overflow: auto;
        padding-inline: 7%;
        padding-block: 4%;

        .title-modal {
          margin-top: 17px; margin-bottom: 7px;
          font-family: $font-assistant; font-weight: 600; font-size: 30px;
        }
        .text{margin-bottom: unset; font-size: 20px;  font-family: $font-assistant; line-height: 1.3;}
      }

      ::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #1ab2c4;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
  .computer-image{
    width: 42%;
    position: fixed;
    inset-inline-end: -7%;
    top: 15%;
    z-index: 1;
  }
  .lang-icon{
    width:27px;
    height:27px;
    margin-inline: 0.5rem
  }
</style>
