import store from './store'

export default class Helpers {
  static get isProd () {
    return process.env.NODE_ENV === 'production'
  }

  static decodeJWT (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(jsonPayload)
  }

  static getIdmURL (classId = null) {
    const link = '/accounts/idm/login/'
    const AUTH_SERVER_PATH = Helpers.env('AUTH_URL')
    const callbackUrl = location.origin + '/' + store.state.librarySlug + '/check-permissions'
    let result = AUTH_SERVER_PATH + link + '?next=' + callbackUrl
    if (classId) {
      result += '&class_id=' + classId
    }
    result += '&ramot=true'
    return result
  }

  static getGoogleURL () {
    const link = '/accounts/classe_google/login'
    const AUTH_SERVER_PATH = Helpers.env('AUTH_URL')
    const callbackUrl = location.href + '/check-permissions'
    return AUTH_SERVER_PATH + link + '?next=' + callbackUrl
  }

  static env (constant, defaultValue = null) {
    const value = process.env[`VUE_APP_${constant}`]

    return typeof value === 'undefined' ? defaultValue : value
  }
}
