<template>
  <div class="page-wrap teacher-class">
    <div class="head">
      <h1>{{$t('MY_LESSONS')}}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "teacher-class",
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.page-wrap {
  transition: opacity 0.5s ease;
  padding: 0 10px;
}
</style>
