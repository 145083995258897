const MUTATION_TYPES = {
  SET_USER: 'setUser',
  LOGOUT_USER: 'logoutUser',
  UNSET_USER: 'unsetUser',
  SET_LOGGEDIN: 'setLoggedIn',
  SET_LIBRARY_SLUG: 'SetLibrarySlug',
  SET_SCHOOL: 'setSchool',
};

export { MUTATION_TYPES };
