<template>
  <div>
    <div class="head-desk d-none d-md-block">
      <div class="header d-flex flex-row">
        <a class="col-1" @click="logout">
          <img src="@/assets/icons/mabat.svg" alt="" />
        </a>
        <a class="col-1" @click="logout">
          <img src="@/assets/icons/univ.svg" alt="" />
        </a>
      </div>
    </div>
    <div class="head-mob d-block d-md-none">
      <div class="head-mob-container">
        <div class="d-flex flex-row align-center">
          <div @click="toggleSideNav" class="col-1">
            <img
              v-if="!toggleMobSideBar"
              class="icon-auth"
              src="@/assets/icons/square.svg"
              alt=""
            />
            <img
              v-else
              class="icon-auth"
              src="@/assets/icons/closeside.svg"
              alt=""
            />
          </div>
          <div class="col-11">
            <img src="@/assets/icons/mabatfooter.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MUTATION_TYPES } from '@/store/types'

export default {
  name: "app-header",
  props: ["toggleSideNav", "toggleMobSideBar"],
  data() {
    return {};
  },

  computed: {
    ...mapState({
      librarySlug: state => state.librarySlug
    })
  },

  methods: {
    logout () {
      this.$store.commit(MUTATION_TYPES.LOGOUT_USER)
    }
  }
};
</script>
<style lang="scss">
.header {
  background: #dcfaff 0% 0% no-repeat padding-box;
  height: 10vh;
  .col-1 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.head-mob {
  .head-mob-container {
    position: fixed;
    width: 100%;
    z-index: 999991;
    background: #dcfaff 0 0 no-repeat padding-box;
    .col-1 {
      padding-right: 15px;
      img {
        width: 35px;
        height: 35px;
        display: block;
        margin: 0 auto;
      }
    }
    .col-11 {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 5vh;
      }
    }
    .head-mob-menu-container {
      position: fixed;
      width: 100%;
      height: 100vh;
      background: #0000005e;
      transition: ease 0.5s;
      .head-mob-menu-wrap {
        width: 320px;
        height: 100%;
        background: white;
        transition: ease 0.3s;
        display: block;
        padding: 15px;
        .avatar-box {
          padding: 10px 0;
          position: relative;
          .avatar-wrapper {
            width: 112px;
            height: 112px;
            border-radius: 50%;
            border: 1px solid #d2d0d0;
            background: #d2d0d0;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            img {
              width: 83px;
              height: 83px;
            }
          }
          .user {
            justify-content: space-evenly;
            padding: 15px 0;
            cursor: pointer;
            p {
              padding-top: 0;
              color: #0f707b;
              font-size: 20px;
              font-weight: 500;
              margin-bottom: 0;
              text-align: center;
            }
            img {
              width: 15px;
              height: 15px;
              &.active {
                transform: rotate(-180deg);
              }
            }
          }
          .user-dropdown {
            display: none;
            position: absolute;
            top: 90%;
            width: 100%;
            -webkit-box-shadow: 0 0 11px 4px rgba(0, 0, 0, 0.58);
            box-shadow: 0 0 11px 4px rgba(0, 0, 0, 0.58);
            height: auto;
            background-color: white;
            z-index: 99;
            cursor: pointer;
            &.active {
              display: flex;
              flex-direction: column;
            }
            .fullname {
              border-bottom: 1px solid #ddd;
              padding: 10px 10px;
              cursor: pointer;
              span {
                margin-left: 5px;
              }
            }
            .school {
              border-bottom: 1px solid #ddd;
              padding: 10px 10px;
              cursor: pointer;
              span {
                margin-left: 6px;
              }

              &.teacher:not(.active) {
                opacity: 0.5;
              }
            }
            .student-class {
              border-bottom: 1px solid #ddd;
              padding: 10px 10px;
              cursor: pointer;
            }
            .logout {
              text-align: right;
              padding: 10px 10px;
              cursor: pointer;
              a {
                text-align: right;
                color: rgb(87, 87, 87);
                text-decoration: rgb(87, 87, 87);
              }
            }
          }
        }
      }
    }
  }
}
</style>
