<template>
  <div class="error-message-container">
      <h2 class="title-modal semi-bold">{{$t('NO_SCHOOL_ERROR_TITLE')}}</h2>
      <p class="text">{{$t('NO_SCHOOL_ERROR_TEXT_1')}}.</p>
      <a href="https://meyda.education.gov.il/files/minhelet/Nmm/NMM_1.pdf" class="link" target="_blank">{{ $t('WRITTEN_GUIDE') }}</a>
      <span> &nbsp;&nbsp; </span>
      <a href="https://youtu.be/r_kI0RRPRek" class="link" target="_blank">{{ $t('GUIDING_VIDEO') }}</a>

      <p class="title-modal semi-bold">{{$t('NO_SCHOOL_ERROR_TEXT_2')}}</p>
      <p class="text">{{$t('NO_SCHOOL_ERROR_TEXT_3')}}.</p>
  </div>
</template>

<script>

  export default {
    name: 'noSchoolInfo',
    data () {
      return {}
    }
  }
</script>
<style lang="scss">
</style>
