<template>
    <div>
        <div class="col-1s">
            <div class="avatar-box">
                <div class="avatar-wrapper">
                    <img :src="getIconByTypeUser" alt="" />
                </div>
                <div @click="toggleDropDown" class="user d-flex flex-row align-center">
                    <p class="current-user" v-if="currentUser">{{$t('HELLO')}} {{ currentUser.first_name }}</p>
                    <img :class="{ active: userDropDown }" class="icon-auth" src="@/assets/icons/rup.svg" alt="rup icon" />
                </div>
                <div class="user-dropdown" :class="{ active: userDropDown }">
                    <div class="fullname d-flex flex-row align-center">
                        <span>{{ currentUser.first_name }}</span>
                        <span>{{ currentUser.last_name }}</span>
                    </div>
    
                    <template v-if="isTeacher">
                <div
                    @click="selectSchool($event, index)"
                    v-for="(school, index) in schools"
                    :key="index"
                    :class="{ active: selectedSchool == index,
                     pointer: schools.length > 1}"
                    class="school teacher d-flex flex-column align-start"
                >
                  <span>{{ school.name }}</span>
                  <span>{{ school.city }}</span>
                </div>
</template>

<template v-else-if="currentUser.classes">
    <div class="student-class">
        <span>{{ currentUser.classes[0].name }}</span>
    </div>
    
    <div v-if="currentUser.schools" class="school d-flex flex-column align-start">
        <span>{{ currentUser.schools[0].name }}</span>
        <span>{{ currentUser.schools[0].city }}</span>
    </div>
</template>

          <div class="logout" @click="logOut">
            <a>{{$t('LOGOUT')}}</a>
          </div>
        </div>
      </div>
      <div
          class="d-flex flex-row align-center justify-center cont-box"
          v-for="(link, index) in menuLinks"
          :key="index"
          :class="{'active': currentRoute === link.path}">
        <router-link :to="{ name: link.path }" class="link" :class="{new: link.path === 'assessment'}">
          <img :src="[currentRoute !== link.path ? link.icon : link.activeicon]" alt="link icon"/>
          <div class="ribbon">{{ $t('NEW') }}!</div>
          <p class="item" :class="{ active: currentRoute === link.path }">
            {{ link.title }}
          </p>
        </router-link>
      </div>
    </div>
    <div class="col-s1-mob" :class="{ active: toggleMobSideBar }">
      <div class="col-s1-mob-wrap" :class="{ active: toggleMobSideBar }">
        <div class="avatar-box">
          <div class="avatar-wrapper">
            <img :src="getIconByTypeUser" alt="user icon"/>
          </div>
          <div
              @click="toggleDropDown"
              class="user d-flex flex-row align-center"
          >
            <p v-if="currentUser">{{$t('HELLO')}} {{ currentUser.first_name }}</p>
            <img
                :class="{ active: userDropDown }"
                class="icon-auth"
                src="@/assets/icons/rup.svg"
                alt="rup icon"
            />
          </div>
          <div class="user-dropdown" :class="{ active: userDropDown }">
            <div class="fullname d-flex flex-row align-center">
              <span>{{ currentUser.first_name }}</span>
              <span>{{ currentUser.last_name }}</span>
            </div>

<template v-if="isTeacher">
    <div @click="selectSchool($event, index)" v-for="(school, index) in schools" :key="index" :class="{ active: selectedSchool == index}" class="school teacher d-flex flex-column align-start">
        <span>{{ school.name }}</span>
        <span>{{ school.city }}</span>
    </div>
</template>

<template v-else-if="currentUser.classes">
    <div class="student-class">
        <span>{{ currentUser.classes[0].name }}</span>
    </div>
    
    <div v-if="currentUser.schools" class="school d-flex flex-column align-start">
        <span>{{ currentUser.schools[0].name }}</span>
        <span>{{ currentUser.schools[0].city }}</span>
    </div>
</template>

            <div class="logout" @click="logOut">
              <a>{{$t('LOGOUT')}}</a>
            </div>
          </div>
        </div>
        <div
            class="d-flex flex-row align-center justify-center cont-box"
            v-for="(link, index) in menuLinks"
            :key="index"
            :class="{
            'cont-box active': currentRoute === link.path,
          }"
        >
          <router-link :to="{ name: link.path }">
            <img :src="[currentRoute !== link.path ? link.icon : link.activeicon]" alt="link icon"/>
            <p class="item" @click="toggleSideNav" :class="{ active: currentRoute === link.path }">
              {{ link.title }}
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '../components/Loader.vue'
import { MUTATION_TYPES } from '../store/types'
import { mapState } from 'vuex'

export default {
   // comment
    name: 'app-sidebar',
    props: ['toggleSideNav', 'toggleMobSideBar'],
    data() {
        return {
            userDropDown: false,
        }
    },
    methods: {
        toggleDropDown() {
            this.userDropDown = !this.userDropDown
        },
        async logOut() {
            this.$store.commit(MUTATION_TYPES.LOGOUT_USER)
        },
        async selectSchool(event, school) {
            if (this.schools.length > 1) {
                this.$store.commit(MUTATION_TYPES.SET_SCHOOL, school)
                await this.$router.replace({ name: 'tasks' })
            }
        },
    },
    computed: {
        ...mapState({
            librarySlug: state => state.librarySlug
        }),
        getIconByTypeUser() {
            if (this.isTeacher) {
                return require('@/assets/icons/teacher-astronaut.png')
            } else {
                return require('@/assets/icons/student-astronaut.svg')
            }
        },
        menuLinks() {
            let assessment = {
                    title: this.$t('ASSESSMENTS'),
                    path: 'assessment',
                    icon: require('@/assets/icons/assessment.svg'),
                    activeicon: require('@/assets/icons/assessment-active.svg'),
                }
            let menu =
             [{
                    title: this.isTeacher ? this.$t('SHARED_TASKS') : this.$t('MY_TASKS'),
                    path: 'tasks',
                    icon: require('@/assets/icons/list.svg'),
                    activeicon: require('@/assets/icons/list-active.svg'),
                },
                // {
                //   title: this.$t('MY_LESSONS'),
                //   path: "classes",
                //   icon: require("@/assets/icons/pen.svg"),
                //   activeicon: require("@/assets/icons/pen.svg"),
                // },
                {
                    title: this.$t('DIGITAL_TEXTBOOK'),
                    path: 'books',
                    icon: require('@/assets/icons/book-icon.svg'),
                    activeicon: require('@/assets/icons/open-book-b-active.svg'),
                },
                
                {
                    title: this.$t('DIGITAL_UNIT_CONTENT'),
                    path: 'content',
                    icon: require('@/assets/icons/lamp.svg'),
                    activeicon: require('@/assets/icons/lampactive.svg'),
                },

                {
                    title: this.$t('INSTRUCTIONAL_VIDEOS'),
                    path: 'videos',
                    icon: require('@/assets/icons/vid.svg'),
                    activeicon: require('@/assets/icons/vidactive.svg'),
                },
            ]
            if (this.isTeacher){
                menu.splice(2,0,assessment)
            }
            return menu
        },
        currentRoute() {
            return this.$route.name
        },
        currentUser() {
             return this.$auth?.user
        },
        schools() {
            return this.currentUser.schools
        },
        selectedSchool() {
            return this.$store.getters['schoolIndex']
        },
        isTeacher() {
            return this.currentUser.isTeacher()
        },
    },
}
</script>

<style lang="scss">
.col-1s {
    max-width: 178px;
    @media (max-width: 959px) {
        display: none;
    }
    .avatar-box {
        padding: 10px 0;
        position: relative;
        .avatar-wrapper {
            width: 109px;
            height: 109px;
            border-radius: 50%;
            background: #d2d0d0;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .user {
            justify-content: space-evenly;
            padding: 15px 0;
            cursor: pointer;
            .current-user {
                padding-top: 0;
                color: #0f707b;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 0;
                text-align: center;
            }
            img {
                width: 15px;
                height: 15px;
                &.active {
                    transform: rotate(-180deg);
                }
            }
        }
        .user-dropdown {
            display: none;
            position: absolute;
            top: 90%;
            width: 100%;
            box-shadow: 0 0 11px 4px rgba(0, 0, 0, 0.58);
            height: auto;
            background-color: white;
            z-index: 689;
            cursor: pointer;
            inset-inline-start: 17px;
            &.active {
                display: flex;
                flex-direction: column;
            }
            .fullname {
                border-bottom: 1px solid #ddd;
                padding: 10px 10px;
                cursor: pointer;
                span {
                    margin-left: 5px;
                }
            }
            .school {
                border-bottom: 1px solid #ddd;
                padding: 10px 10px;
                cursor: pointer;
                span {
                    margin-left: 6px;
                }
                &.teacher:not(.active) {
                    opacity: 0.5;
                }
            }
            .pointer {
                cursor: pointer;
            }
            .student-class {
                border-bottom: 1px solid #ddd;
                padding: 10px 10px;
                cursor: pointer;
            }
            .logout {
                text-align: right;
                padding: 10px 10px;
                cursor: pointer;
                a {
                    text-align: right;
                    color: rgb(87, 87, 87);
                    text-decoration: rgb(87, 87, 87);
                }
            }
        }
    }
    .cont-box {
        cursor: pointer;
        border-left: 4px solid transparent;
        position: relative;
        width: 100%;
        padding: 10px 15px;
        &.active {
            border-left: 4px solid #0f707b;
        }
        &.disabled {
            opacity: 0.38;
            pointer-events: none;
        }
        a {
            text-decoration: none;
            img {
                width: 45px;
                height: 45px;
                display: block;
                margin: 0 auto;
            }
            .item {
                font-size: 20px;
                font-weight: 200;
                color: #434242;
                text-align: center;
                margin: 0;
                padding: 15px 0;
                line-height: 1;
                &.active {
                    color: #0f707b;
                    font-weight: bold;
                }
            }
        }
    }
}
@media (max-width: 959px) {
  body:has(.col-s1-mob.active){
    overflow: hidden; position: fixed;
  }
}

.col-s1-mob {
    display: none;
    @media (max-width: 959px) {
        position: fixed;
        width: 100%;
        height: 100%;
        background: #0000005e;
        z-index: 99999;
        display: none;
        &.active {
            display: block;
        }
        .col-s1-mob-wrap {
            background: white; width: 200px; overflow: auto;
            height: 100vh; padding-bottom: 20px; padding-top: 105px;
            .avatar-box {
                padding: 10px 0;
                position: relative;
                .avatar-wrapper {
                    width: 112px;
                    height: 112px;
                    border-radius: 50%;
                    border: 1px solid #d2d0d0;
                    background: #d2d0d0;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    img {
                        width: 83px;
                        height: 83px;
                    }
                }
                .user {
                    justify-content: space-evenly;
                    padding: 15px 0;
                    cursor: pointer;
                    p {
                        padding-top: 0;
                        color: #0f707b;
                        font-size: 20px;
                        font-weight: 500;
                        margin-bottom: 0;
                        text-align: center;
                    }
                    img {
                        width: 15px;
                        height: 15px;
                        &.active {
                            transform: rotate(-180deg);
                        }
                    }
                }
                .user-dropdown {
                    display: none;
                    position: absolute;
                    top: 90%;
                    width: 100%;
                    box-shadow: 0 0 11px 4px rgba(0, 0, 0, 0.58);
                    height: auto;
                    background-color: white;
                    z-index: 99;
                    &.active {
                        display: flex;
                        flex-direction: column;
                    }
                    .fullname {
                        border-bottom: 1px solid #ddd;
                        padding: 10px 10px;
                        cursor: default;
                        span {
                            margin-left: 5px;
                        }
                    }
                    .school {
                        border-bottom: 1px solid #ddd;
                        padding: 10px 10px;
                        cursor: default;
                        span {
                            margin-left: 6px;
                        }
                        &.teacher:not(.active) {
                            opacity: 0.5;
                        }
                    }
                    .student-class {
                        border-bottom: 1px solid #ddd;
                        padding: 10px 10px;
                        cursor: pointer;
                    }
                    .logout {
                        text-align: right;
                        padding: 10px 10px;
                        cursor: pointer;
                        a {
                            text-align: right;
                            color: rgb(87, 87, 87);
                            text-decoration: rgb(87, 87, 87);
                        }
                    }
                }
            }
            .cont-box {
                cursor: pointer;
                border-left: 4px solid transparent;
                position: relative;
                width: 100%;
                padding: 10px 15px;
                &.active {
                    border-left: 4px solid #0f707b;
                }
                a {
                    text-decoration: none;
                    img {
                        width: 45px;
                        height: 45px;
                        display: block;
                        margin: 0 auto;
                    }
                    .item {
                        font-size: 20px;
                        font-weight: 200;
                        color: #434242;
                        text-align: center;
                        margin: 0;
                        padding: 15px 0;
                        line-height: 1;
                        &.active {
                            color: #0f707b;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}
.link{
  position: relative;
}
.ribbon{
  display: none; inset: 0 0 auto auto; transform-origin: 0 0; transform: translate(51.3%) rotate(45deg);
  position: absolute; background: #FFA700; color: #fff; width: 90px; height: 20px; text-align: center;
  clip-path: polygon(25% 0%, 73% 0%, 100% 100%, 0% 100%); top: -37px; font-size: 14px; line-height: 20px;
  inset-inline-start: 3px; font-family: Arimo, sans-serif; font-weight: 600;
  &:not(.arb &){letter-spacing: 1.5px;}
  .new &{display: block;}
  .arb &{transform: translate(80.3%) rotate(45deg); letter-spacing: 1.2px;}
}
</style>
