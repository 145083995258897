<template>
  <div @keydown.esc="closeChangePassPopup"
       class="modal active-desc">
    <loader class="loader" v-if="isPopupLoading"></loader>
    <div class="modal-container d-flex flex-column">
      <div class="d-flex flex-row head-modal">
        <p>{{$t('CHANGE_PASSWORD')}}</p>
         <button class="close" @click="closeChangePassPopup">
          <img src="@/assets/icons/closewhite.svg" alt="close icon" />
        </button>
      </div>
      <section class="popup-confirm-main-content">
        <div>
          <div v-if="passwordSent" class="success">
            <p class="text">{{ $t('NEW_PASSWORD_SET_SUCCESSFULLY') }}.</p>
          </div>
          <form v-else class="d-flex flex-column" @submit="changePassword">
            <input type="password" :placeholder="$t('NEW_PASSWORD')" v-model="password"
                   @input="$v.password.$touch()"
                   :class="{ error: $v.password.$error }">
            <span class="error-text"
                  v-if="$v.password.$error && $v.password.required">
                  {{ $t(passwordErrorText) }}
            </span>
            <input type="password" :placeholder="$t('CONFIRM_NEW_PASSWORD')" v-model="confirmPassword"
                   @input="$v.confirmPassword.$touch()"
                   :class="{ error: $v.confirmPassword.$error }">
            <span class="error-text"
                  v-if="$v.password.required && $v.confirmPassword.required && !$v.confirmPassword.sameAs">
                  {{ $t('DIF_PASS') }}
            </span>
            <button type="submit" :class="hasErrors ? 'disabled' : null">
              {{$t('SEND')}}
            </button>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import { required, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'
  import { mapState } from 'vuex'
  import Loader from '@/components/Loader'

  export default {
    name: 'ConfirmPassword',
    components: { Loader },
    data () {
      return {
        passwordSent: false,
        password: null,
        confirmPassword: null,
      }
    },
    validations: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(16),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value)
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value)
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value)
        },
        isOnlyEnglishLetters: function (value) {
          return /^[\x20-\x7E]*$/.test(value)
        }
      },
      confirmPassword: {
        required,
        sameAs: sameAs('password')
      }
    },
    methods: {
      changePassword (event) {
        event.preventDefault()
        if (this.hasError) return
        this.$store.dispatch('setPopupLoading', true)
        this.$store.dispatch('changePassword', {
            new_password1: this.password, new_password2: this.confirmPassword,
            uid: this.$route.params.uid, token: this.$route.params.token
          }
        ).then((response) => {
            // if reset password success
            this.passwordSent = true
            setTimeout(() => {
              this.closeChangePassPopup()
              this.$store.dispatch('setPopupLoading', false)
            }, 5000)
          }
        ).catch(error => {
          console.log(error)
          this.$store.dispatch('setPopupLoading', false)
        })
      },
      closeChangePassPopup () {
        this.$router.push({ name: 'home' })
      }
    },
    computed: {
      hasErrors () {
        return !this.$v.password.required || this.$v.password.$error || !this.$v.confirmPassword.required || this.$v.confirmPassword.$error
      },
      ...mapState({
        isPopupLoading: state => state.isPopupLoading
      }),
      passwordErrorText () {
        if (!this.$v.password.isOnlyEnglishLetters) {
          return ('PASSWORD_LETTERS_MUST_BE_IN_ENGLISH_ONLY')
        } else if (!this.$v.password.minLength || !this.$v.password.maxLength) {
          return ('PASSWORD_MUST_BE_BETWEEN_8_AND_16_CHARACTERS_LONG')
        } else if (!this.$v.password.containsLowercase) {
          return ('PASSWORD_MUST_CONTAIN_LOWERCASE_LETTER')
        } else if (!this.$v.password.containsUppercase) {
          return ('PASSWORD_MUST_CONTAIN_UPPERCASE_LETTER')
        } else if (!this.$v.password.containsNumber) {
          return ('PASSWORD_MUST_CONTAIN_NUMBER')
        }
        return (null)
      }
    }
  }
</script>
<style lang="scss">
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: #0000005e;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;

    &.active-desc {
      display: flex;
      z-index: 999;
      .loader {
        z-index: 1;
        background: transparent;
      }
    }

    form {

      .disabled {
        background: #d5d5d5;
        pointer-events: none;
      }

      .error {
        border: 2px solid red;
        background-color: transparent !important
      }

      .error-text {
        margin-right: 5px;
        font-size: 12px;
        color: #FF0000;
      }

      input {
        height: 47px;
        border: 1px solid #707070;
        border-radius: 15px;
        font-size: 20px;
        padding: 0 15px;
        margin: 10px 0;
        outline: none;
      }

      button {
        background: #1ab2c4;
        color: white;
        text-align: center;
        font-size: 30px;
        border-radius: 17px;
        cursor: pointer;
      }
    }

    @-webkit-keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }
    @keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }

    .modal-container {
      background: white;
      border-radius: 25px;

      -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      .close {
        padding: 8px 0;
        cursor: pointer;

        svg {
          width: 15px;
          height: 15px;
          margin-top: 8px;
        }
      }
    }

    @-webkit-keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }
    @keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }

    .modal-container {
      background: white;
      border-radius: 15px;
      @media all and (min-width: 960px){
        min-width: 450px; max-width: 1040px;
      }
      @media all and (min-width: 601px) and (max-width: 959px) {
        max-width: 550px;
      }
      @media all and (min-width: 541px) and (max-width: 600px) {
        max-width: 510px;
      }
      @media all and (min-width: 501px) and (max-width: 540px) {
        max-width: 470px;
      }
      @media all and (min-width: 441px) and (max-width: 500px) {
        max-width: 400px;
      }
      @media all and (min-width: 381px) and (max-width: 440px) {
        max-width: 390px;
      }
      @media (max-width: 380px) {
        max-width: 315px;
      }

      .close {
        img {
          width: 15px;
          height: 15px;
        }
      }


      .popup-confirm-main-content {
        padding: 7%;

        .success {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 5%;
          padding-right: 10%;
          padding-left: 10%;
          background-color: transparent !important;

          p {
            font-size: 24px;
          }
        }
      }


      .head-modal {
        background: #1ab2c4;
        padding: 0px 20px;
        justify-content: space-between;
        align-items: center;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;

        p {
          font-size: 30px;
          color: white;
          margin-bottom: 0px;
          font-weight: bold;
          padding: 10px 0;
          padding-right: 32%;
        }

        .close {
          cursor: pointer;

          svg {
            width: 20px;
            height: 20px;
            margin-top: 8px;
          }
        }
      }

      ::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #1ab2c4;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
</style>
