import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuelidate from 'vuelidate'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import "swiper/swiper-bundle.css";
import "./scss/main.scss";

Vue.use(VueAwesomeSwiper);
Vue.use(Vuelidate)

// API Client
import Api from "./api";
import Auth from "./auth";
import i18n from './i18n'
Api.instance();

Vue.prototype.$api = Api.instance();
Vue.prototype.$auth = Auth.instance();
Vue.prototype.$scrollToTop = () => window.scrollTo(0,0)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
