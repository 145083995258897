<template>
  <div>
    <div class="navabout">
      <Navbar :logoUrl="logoUrl"/>
    </div>
    <div class="about">
      <div class="sec-one">
        <div class="about-container d-flex flex-column flex-md-row">
          <div class="col-12 col-md-8">
            <h1>{{$t('ABOUT')}}</h1>
            <div class="content">
              <p>
               {{$t('ABOUT_CONTENT_1')}}.
              </p>
              <p>
                {{$t('ABOUT_CONTENT_2')}}.
               </p>
              <p>
                {{$t('ABOUT_CONTENT_3')}}.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4 d-flex text-center">
            <img src="@/assets/icons/mac.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="sec-two">
        <h1 class="text-center">
          {{$t('ABOUT_SEC_TWO')}}:
        </h1>
        <div class="sec-two-container">
          <div class="col-10 col-md-5">
            <div class="circle">
              <img src="@/assets/icons/bookabout.svg" alt="" />
            </div>
            <div class="wrapper-box">
              <h3>{{$t('DIGITAL_TEXTBOOKS')}}</h3>
              <div class="content-wrapper">
                <p>{{$t('ABOUT_DIGITAL_BOOKS_1')}}</p>
                <p>{{$t('ABOUT_DIGITAL_BOOKS_2')}}</p>
              </div>
            </div>
          </div>
          <div class="col-10 col-md-5">
            <div class="circle">
              <img src="@/assets/icons/monitor.svg" alt="" />
            </div>
            <div class="wrapper-box">
              <h3>{{$t('DIGITAL_UNIT_CONTENT')}}</h3>
              <div class="content-wrapper"><p>
                {{$t('ABOUT_DIGITAL_UNIT_CONTENT')}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sec-three">
        <div class="sec-three-container d-flex flex-column flex-md-row">
          <div class="col-3"></div>
          <div class="col-9">
            <div class="col-4">
              <div class="bg-wrapper">
                <img class="bgabout" src="@/assets/icons/bgabout.png" alt="" />
              </div>
            </div>
            <div class="col-8">
              <div class="wrapper-content">
                <p>{{$t('ABOUT_SEC_THREE_1')}}</p>
                <p>{{$t('ABOUT_SEC_THREE_2')}}</p>
                <p>{{$t('ABOUT_SEC_THREE_3')}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sec-four">
        <h1 class="text-center">
          {{$t('ABOUT_SEC_FOUR_TITLE')}}?
        </h1>
        <div class="sec-two-container">
          <div class="col-10 col-md-5">
            <div class="circle">
              <img src="@/assets/icons/teacher.png" alt="" />
            </div>
            <div class="wrapper-box">
              <h3>{{$t('FOR_TEACHERS')}}</h3>
              <div class="content-wrapper">
                <ul>
                  <li><b>{{$t('ONLINE_LEARNING_SPACE')}}:</b>
                  {{$t('ONLINE_LEARNING_SPACE_ABOUT')}}.</li>
<!--                  <li><b>{{$t('CREATE_NEW_CONTENT')}}:</b>-->
<!--                  {{$t('CREATE_NEW_CONTENT_ABOUT')}}.</li>-->
                  <li><b>{{$t('LMS')}} (LMS):</b> 
                  {{$t('LMS_ABOUT')}}</li>
                  <li><b>{{$t('REPORTS')}}:</b> 
                  {{$t('REPORTS_ABOUT')}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-10 col-md-5">
            <div class="circle">
              <img src="@/assets/icons/talmid.png" alt="" />
            </div>
            <div class="wrapper-box">
              <h3>{{$t('FOR_STUDENTS')}}</h3>
              <div class="content-wrapper">
                <ul>
                  <li><b>{{$t('ONLINE_LEARNING_SPACE')}}: </b>
                  {{$t('ONLINE_LEARNING_SPACE_ABOUT')}}.</li>
                 <li><b>{{$t('LMS')}}: </b>
                 {{$t('LMS_ABOUT_STUDENT')}}.</li>
                 <li><b>{{$t('LEARNING_ASSESSMENT')}}: </b>
                 {{$t('LEARNING_ASSESSMENT_ABOUT')}}.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Navbar from "../components/Navbar.vue";
import { mapState } from 'vuex'

export default {
  name: "about-page",
  components: { Header, Footer, Navbar },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      logoUrl: state => state.logoUrl
    })
  }
};
</script>
<style lang="scss">
.navabout {
  background: #dcfaff;
  height: 15vh !important;
  @media (max-width: 959px) {
    height: 0 !important;
    background: transparent !important;
  }
}
.about {
  padding-top: 80px;
  .sec-one {
    .about-container {
      max-width: 1600px;
      margin: 0 auto;
      @media (max-width: 1600px) {
        padding: 0 50px;
      }
      .col-md-4 {
        img {
          max-width: 100%;
          height: auto;
          @media (max-width: 1600px) {
            margin: 0 auto;
          }
          @media (max-width: 959px) {
            padding: 0 18px;
          }
        }
      }
      .col-md-8 {
        h1 {
          color: #0f707b;
          font-size: 60px;
          @media (max-width: 959px) {
            font-size: 50px;
          }
          @media (max-width: 959px) {
            font-size: 40px;
          }
          @media (max-width: 959px) {
            font-size: 35px;
          }
        }
        .content {
          p {
            font-size: 26px;
            margin-bottom: 6px;
            @media (max-width: 959px) {
              font-size: 22px;
            }
            @media (max-width: 959px) {
              font-size: 20px;
            }
            @media (max-width: 959px) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .sec-two {
    padding-bottom: 100px;
    @media (max-width: 959px) {
      padding-bottom: 60px;
    }
    @media (max-width: 560px) {
      padding-bottom: 20px;
    }
    h1 {
      color: #0f707b;
      font-size: 40px;
      padding-bottom: 45px;
      font-weight: 400;
      @media (max-width: 959px) {
        font-size: 50px;
        padding-bottom: 30px;
      }
      @media (max-width: 959px) {
        font-size: 40px;
      }
      @media (max-width: 959px) {
        font-size: 35px;
      }
    }
    .sec-two-container {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
      padding: 15px 0;
      @media (max-width: 959px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .col-10,
      .col-md-5 {
        border-radius: 15px;
        margin: 50px 0;
        .circle {
          position: absolute;
          background: #c3f3fb;
          width: 110px;
          height: 110px;
          top: -60px;
          border-radius: 50%;
        }
        .wrapper-box {
          @media (max-width: 959px) {
            text-align: center;
            padding: -1px 30px;
            width: 100%;
            padding-top: 40px;
          }
          h3 {
          }
        }
      }
      .col-10,
      .col-md-5 {
        border-radius: 15px;
        display: flex;
        justify-content: center;
        position: relative;

        background: #f5f5f5;
        .circle {
          position: absolute;
          background: #c3f3fb;
          width: 110px;
          height: 110px;
          top: -60px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 80px;
            height: 80px;
          }
        }
        .wrapper-box {
          padding: 60px 30px;
          width: 100%;
          @media (max-width: 959px) {
            padding: 0 0px;
            padding-top: 45px;
          }
          .content-wrapper {
            font-size: 20px;
            p {
              margin-bottom: 5px;
            }
          }
          h3 {
            color: #0f707b;
            font-size: 27px;
            text-align: center;
            padding-bottom: 15px;
          }
          li {
            font-size: 20px;
            colo: black;
            font-weight: 400;
            margin-bottom: 6px;
            @media (max-width: 959px) {
              font-size: 18px;
              text-align: center;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .sec-three {
    max-width: 1600px;
    margin: 0 auto;
    .sec-three-container {
      @media (max-width: 1600px) {
        margin: 0 auto;
      }
      .col-3 {
        @media (max-width: 1600px) {
          display: none;
        }
      }
      .col-9 {
        display: flex;
        flex-direction: row;
        @media (max-width: 1600px) {
          flex: 0 0 100%;
          max-width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
        @media (max-width: 959px) {
          flex: 0 0 100%;
          max-width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .col-8 {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
          background: #c3f3fb;
          padding: 25px 0;
          border: 1px solid transparent;
          @media (max-width: 1600px) {
            flex: 0 0 50%;
            max-width: 50%;
          }
          @media (max-width: 959px) {
            flex: 0 0 85%;
            max-width: 85%;
            border-radius: 25px;
          }
          .wrapper-content {
            padding: 0 50px;
            @media (max-width: 959px) {
              padding: 0px 15px;
              padding-top: 115px;
            }
            p {
              font-size: 20px;
              font-weight: 400;
              color: black;
              margin-bottom: 6px;
              @media (max-width: 959px) {
                font-size: 18px;
              }
            }
          }
        }
        .col-4 {
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
          background: #c3f3fb;
          position: relative;
          @media (max-width: 959px) {
            flex: 0 0 100%;
            width: 100%;
            background: transparent;
          }
          .bg-wrapper {
            position: absolute;
            top: 0;
            left: 20px;
            width: 550px;
            height: 100%;
            display: flex;
            align-items: center;
            @media (max-width: 1600px) {
              width: 100%;
            }
            @media (max-width: 959px) {
              flex: 0 0 100%;
              max-width: 100%;
              justify-content: center;
              left: 0;
              right: 0;
            }
            img {
              width: 550px;
              height: auto;
              border-radius: 25px;
              @media (max-width: 1600px) {
                max-width: 90%;
              }
              @media (max-width: 959px) {
                max-width: 450px;
                height: auto;
                border-radius: 25px;
              }
              @media (max-width: 560px) {
                max-width: 350px;
                height: auto;
                border-radius: 25px;
              }
              @media (max-width: 460px) {
                max-width: 300px;
                height: auto;
                border-radius: 25px;
              }
            }
          }
        }
      }
    }
  }
  .sec-four {
    padding-top: 100px;
    padding-bottom: 45px;
    @media (max-width: 959px) {
      padding-top: 40px;
      padding-bottom: 10px;
    }
    h1 {
      color: #0f707b;
      font-size: 40px;
      padding-bottom: 45px;
      font-weight: 400;
      @media (max-width: 959px) {
        padding-bottom: 0px;
        padding: 0 50px;
      }
    }
    .sec-two-container {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
      padding: 15px 0;
      @media (max-width: 959px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .col-10,
      .col-md-5 {
        border-radius: 15px;
        margin: 50px 0;

        .circle {
          position: absolute;
          background: #c3f3fb;
          width: 110px;
          height: 110px;
          top: -60px;
          border-radius: 50%;
        }
      }

      .col-10,
      .col-md-5 {
        border-radius: 15px;
        display: flex;
        justify-content: center;
        position: relative;

        background: #f5f5f5;

        .circle {
          position: absolute;
          background: #c3f3fb;
          width: 110px;
          height: 110px;
          top: -60px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 80px;
            height: 80px;
          }
        }

        .wrapper-box {
          padding: 60px 30px;
          width: 100%;
          @media (max-width: 959px) {
            text-align: center;
            padding: 0px 0px;
          }

          .content-wrapper {
            font-size: 20px;
            p {
              margin-bottom: 5px;
            }
          }

          h3 {
            color: #0f707b;
            font-size: 27px;
            text-align: center;
            padding-bottom: 15px;
          }

          li {
            font-size: 20px;
            colo: black;
            font-weight: 400;
            margin-bottom: 6px;
            @media (max-width: 959px) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
</style>
