import helpers from './helpers'
import axios from 'axios'
import store from './store'
import { MUTATION_TYPES } from './store/types'

export default class Api {
  static _instance

  constructor () {
    this.baseApi = helpers.env('BASE_URL_API')
    this.baseHome = helpers.env('BASE_URL')

    this.client = axios.create({
      baseURL: this.baseApi,
      headers: this.getHeaders(),
      withCredentials: true
    })

    this.clientHome = axios.create({
      baseURL: this.baseHome,
      headers: this.getHeaders(),
      withCredentials: true,
    })

    this.client.interceptors.request.use(
      (config) => {
        config.headers = this.getHeaders()

        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    this.clientHome.interceptors.request.use(
      (config) => {
        config.headers = this.getHeaders()

        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  getHeaders (json = true) {
    this.token = sessionStorage.getItem('token')
    let headers = {
      Accept: 'application/json',
    }

    if (json) {
      headers['Content-Type'] = 'application/json'
    }

    if (this.token) {
      headers.Authorization = `JWT ${this.token}`
      const jwt = helpers.decodeJWT(this.token)
      const exp = jwt.exp || Date.now
      const diff = Date.now() - exp * 1000
      if (!exp || diff > 0) {
        console.log('current diff: ' + diff)
        alert('session expired')
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('school')
        location.reload()
      }
    }

    return headers
  }

  /**
   *
   * @param {string} type
   * @param {string} method
   * @param {string} path
   * @param {*} data
   * @param {AxiosRequestConfig} options
   * @returns {Promise<*>}
   */
  async request (type, method, path, data, options) {
    let response = null

    try {
      response = type == 'home' ? await this.clientHome.request({
        url: path,
        method,
        data,
        ...options,
      }) : await this.client.request({
        url: path,
        method,
        data,
        ...options,
      })
    } catch (error) {
      error.handeled = false

      if (error.response) {
        response = error.response
      }

      throw error
    }

    return response.data
  }

  /**
   *
   * @param {string} path
   * @param {*} body
   * @param {AxiosRequestConfig} options
   * @returns {Promise<*>}
   */
  post (path, body = {}, options = {}) {
    return this.request('api', 'post', path, body, options)
  }

  postHome (path, body = {}, options = {}) {
    return this.request('home', 'post', path, body, options)
  }

  /**
   *
   * @param {string} path
   * @param {Object} params
   * @param {AxiosRequestConfig} options
   * @returns {Promise<*>}
   */
  get (path, params = {}, options = {}) {
    return this.request(
      'api',
      'get',
      path,
      {},
      {
        ...options,
        params,
      }
    )
  }

  getHome (path, params = {}, options = {}) {
    return this.request(
      'home',
      'get',
      path,
      {},
      {
        ...options,
        params,
      }
    )
  }

  /**
   *
   * @param {string} path
   * @param {*} body
   * @param {AxiosRequestConfig} options
   * @returns {Promise<*>}
   */
  patch (path, body = {}, options = {}) {
    return this.request('api', 'patch', path, body, options)
  }

  put (path, body = {}, options = {}) {
    return this.request('api', 'put', path, body, options)
  }

  patchHome (path, body = {}, options = {}) {
    return this.request('home', 'patch', path, body, options)
  }

  /**
   *
   * @param {string} path
   * @param {Object} params
   * @param {AxiosRequestConfig} options
   * @returns {Promise<*>}
   */
  delete (path, params = {}, options = {}) {
    return this.request(
      'api',
      'delete',
      path,
      {},
      {
        ...options,
        params,
      }
    )
  }

  deleteHome (path, params = {}, options = {}) {
    return this.request(
      'home',
      'delete',
      path,
      {},
      {
        ...options,
        params,
      }
    )
  }

  /**
   *
   * @returns {Api}
   */
  static instance () {
    if (!Api._instance) {
      Api._instance = new Api()
    }

    return Api._instance
  }
}
