import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Store from '@/store/index.js'
import LoginError from '../components/LoginError'
import DashboardIndex from '../views/Dashboard/Index.vue'
import Tasks from '../views/Dashboard/Pages/Tasks.vue'
import Videos from '../views/Dashboard/Pages/Videos.vue'
import Classes from '../views/Dashboard/Pages/Classes.vue'
import DigitalBooks from '../views/Dashboard/Pages/DigitalBooks.vue'
import Content from '../views/Dashboard/Pages/Content.vue'
import SelectSchool from '../views/Dashboard/Pages/SelectSchool.vue'
import TeacherClass from '../views/Dashboard/Pages/TeacherClass.vue'
import ResetPassword from '../components/ResetPassword'
import ConfirmPassword from '../components/ConfirmPassword'
import notPurchasedError from '../components/notPurchasedError.vue'
import noSchoolInfo from '../components/noSchoolInfo.vue'
import noTeacherInfo from '../components/noTeacherInfo.vue'
import generalError from '../components/generalError.vue'
import helpers from '@/helpers'

import guest from '../middleware/guest'
import auth from '../middleware/auth'
import { MUTATION_TYPES } from '../store/types'
import { getLoginErrorMessage } from '../components/utilityFunctions.js'
import store from '../store'

Vue.use(VueRouter)

function checkAuth (to, from, next) {
  Vue.prototype.$auth.get_jwt().then(async function (response) {
    if (response.data != 'None') {
      sessionStorage.setItem('token', response.data)
    }
    let nextRoute = null
    // If the user have permission -
    // Go to the internal pages.
    // If not - we will get an error page

    let schoolId = null
    if (to.params.slug === 'ramot-heb') {
      schoolId = helpers.env('HE_SCHOOL_ID')
    } else {
      schoolId = helpers.env('ARB_SCHOOL_ID')
    }
    await Vue.prototype.$api.get('/my-profile/classes/?school_id=' + schoolId).then(async (myProfile) => {
      Store.commit(MUTATION_TYPES.SET_USER, {
        user: myProfile,
        token: response.data,
      })
      Store.commit(MUTATION_TYPES.SET_LOGGEDIN, true)
      if (!myProfile.schools.length) {
        const schoolData = await Vue.prototype.$auth.getUserSchools()
        const loginErrorMessage = getLoginErrorMessage(schoolData)
        Store.commit(MUTATION_TYPES.LOGOUT_USER, loginErrorMessage)
        return
      } else {
        if (myProfile.schools.length > 1) {
          if (!Store.state.school) {
            nextRoute = 'select_school'
          } else {
            nextRoute = 'dashboard'
          }
        } else {
          Store.commit(MUTATION_TYPES.SET_SCHOOL, 0)
          nextRoute = 'dashboard'
        }
      }
    }).catch(() => {
      nextRoute = 'loginError'
    })
    next({
      name: nextRoute,
      params: {
        slug: to.params.slug
      },
      replace: true
    })
  })
}

const routes = [
  {
    path: '/',
    name: 'base',
    redirect: '/ramot-heb'
  }, {
    path: '/:slug/',
    name: 'home',
    component: Home,
    children: [
      {
        path: 'reset-password',
        name: 'resetPassword',
        component: ResetPassword,
      },
      {
        path: 'confirm-password/:uid/:token',
        name: 'confirmPassword',
        component: ConfirmPassword,
      },
      {
        path: 'login-error',
        name: 'loginError',
        component: LoginError,
        children: [
          {
            path: 'not-purchased-error',
            name: 'notPurchasedError',
            component: notPurchasedError
          },
          {
            path: 'no-school-info',
            name: 'noSchoolInfo',
            component: noSchoolInfo
          },
          {
            path: 'no-teacher-info',
            name: 'noTeacherInfo',
            component: noTeacherInfo
          },
          {
            path: '/',
            alias: 'general-error',
            name: 'generalError',
            component: generalError
          }
        ]
      },
      {
        path: 'check-permissions',
        name: 'checkPermissions',
        beforeEnter: (to, from, next) => {
          checkAuth(to, from, next)
          next()
        }
      },
      {
        path: 'join/:classId',
        name: 'joinToClass'
      }
    ]
  },
  {
    path: '/:slug/main/dashboard',
    name: 'dashboard',
    component: DashboardIndex,
    redirect: '/:slug/main/dashboard/digital-books',
    children: [
      {
        path: 'select-school',
        name: 'select_school',
        component: SelectSchool,
      },
      {
        name: 'tasks',
        path: 'tasks',
        component: Tasks,
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'classes',
        path: 'classes',
        component: TeacherClass,
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'books',
        path: 'digital-books',
        component: DigitalBooks,
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'content',
        path: 'content',
        component: Content,
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'assessment',
        path: 'assessment',
        component: Content,
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'videos',
        path: 'videos',
        component: Videos,
        meta: {
          middleware: auth,
        },
      },
    ],
  },
  {
    path: '/:slug/about',
    name: 'About',
    component: About,
  },
  {
    path: '/:slug/videos',
    name: 'Videos',
    component: Videos,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

function nextFactory (context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

export default router
