<template>
  <div>
    <Loader v-if="!digitalbooks" />
    <div v-if="digitalbooks" class="section-one">
      <header>
        <h1>{{$t('DIGITAL_BOOKS')}}</h1>
      </header>
      <div class="section-one-books">
        <v-row class="d-flex justify-center" v-if="digitalbooks" no-gutters>
          <div
            class="card-container"
            v-for="(item, index) in digitalbooks"
            :key="index"
          >
            <div class="item-card">
              <button class="image-btn" @click="openEntryToPreview({entry: item})">
                <img :src="item.image" alt="" />
                <span class="text">{{ item.title }}</span>
              </button>
            </div>
          </div>
        </v-row>
        <img
          class="approval-icon"
          :src="getApprovalIcon" alt="icon"
        />
        <img class="rocket" src="@/assets/icons/rocket.svg" alt="icon" />
      </div>
      <div class="under-line" v-if="unitcontent.length"></div>

      <div v-if="unitcontent.length" class="d-flex flex-column section-two-tech-unit">
        <header>
          <h1>{{$t('DIGITAL_UNIT_CONTENT')}}</h1>
          <img class="dubi" src="@/assets/icons/ale.svg" alt="icon"/>
        </header>
        <Loader v-if="!unitcontent" />
        <div class="points-container d-flex flex-row align-center border">
          <span class="title">{{$t('SELECT_CLASS')}}:</span>
          <div
            class="d-flex flex-row align-center"
            v-for="i in 6"
            :key="i"
          >
            <div
              @click="handleSelectGrade(i)"
              class="class-point"
              :class="{ 'active': currentGrade === i }"
            >
              <p>{{ $t('GRADE_' + i) }}</p>
            </div>
          </div>
        </div>
        <div
          class="selected-class d-flex flex-row align-center flex-wrap"
          v-if="classifications"
        >
          <div
            class="selected-class-wrap"
            @click="handleSelectClassification(-1)"
          >
            <p :class="{ active: currentClassification === -1 }">
              {{$t('ALL')}}
            </p>
          </div>

          <div
            class="selected-class-wrap"
            @click="handleSelectClassification(classification.id)"
            v-for="(classification, i) in getGradeClassifications"
            :key="i"
          >
            <p :class="{ active: currentClassification === classification.id }">
              {{ classification.value }}
            </p>
          </div>
        </div>
      </div>

      <div class="swiper-box" v-if="unitcontent.length">
        <div class="b-r">
          <div class="swiper-b-prev">
            <img src="@/assets/icons/left-arrow.svg" alt="arrow icon" />
          </div>
        </div>
        <swiper
          ref="mySwiperA"
          class="swipe"
          @click="onSwiper"
          :options="swiperOptions"
        >
          <swiper-slide v-for="(card, index) in getGradeClasses" :key="index" v-if="!card.assessment">
            <div class="item-box">
              <div class="card-image">
                <img :src="card.image" alt="" />
                <div class="content-wrapper-digital" @click="openEntryToPreview({entry: card, isContentUnit: true})">
                  <div class="info-box">
                    <div @click="$event.stopPropagation(); showDescCart(card)" class="box">
                      <img
                        @click="$event.stopPropagation(); showDescCart(card)"
                        class="i"
                        src="@/assets/icons/info.svg"
                        alt="icon info"
                      />
                    </div>
                  </div>
                  <div class="content-box-cart d-flex flex-column">
                    <p>{{ card.title }}</p>
                    <div v-if="getSelectedClassification(card)" class="sub d-flex">
                      <div class="d-flex flex-row align-center" v-for="(classification, index) in getSelectedClassification(card)">
                        <p>{{ classification.value }}</p>
                        <div class="line-h" v-if="index<getSelectedClassification(card).length-1">|</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="b-l">
          <div class="swiper-b-next">
            <img src="@/assets/icons/right-arrow.svg" alt="icon arrow" />
          </div>
        </div>
        <img
            class="approval-icon"
            :src="getApprovalIcon" alt="icon"
        />
      </div>

      <Modal :currentCartDesc="currentCartDesc" :showDescCart="showDescCart" :isContentUnit="true"/>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import { mapActions, mapGetters, mapState } from 'vuex'
import User from '../models/User'

export default {
  name: "digital-books",
  props: ["digitalbooks", "baseunitcontent", "classifications", "primaryClassificationTitle"],
  data() {
    return {
      unitcontent: [],
      currentGrade: 1,
      currentClassification: -1,
      defaultImg: "defimg.png",
      staticData: null,
      entries: null,
      node: null,
      currentCartDesc: null,
      toggleModal: false,
      swiperOptions: {
        initialSlide: 0,
        slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-b-next",
          prevEl: ".swiper-b-prev",
        },
        breakpoints: {
          1400: {
            slidesPerView: 3,
            spaceBetween: 0,
            slidesPerColumn: 2,
          },
          1300: {
            slidesPerView: 3,
            spaceBetween: 0,
            slidesPerColumn: 2,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 0,
            slidesPerColumn: 2,
          },
          1100: {
            slidesPerView: 3,
            spaceBetween: 0,
            slidesPerColumn: 2,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 0,
            slidesPerColumn: 2,
          },
          959: {
            slidesPerView: 2,
            spaceBetween: 0,
            slidesPerColumn: 2,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
            slidesPerColumn: 2,
          },
          540: {
            slidesPerView: 1,
            spaceBetween: 0,
            slidesPerColumn: 1,
          },
          400: {
            slidesPerView: 1,
            spaceBetween: 0,
            slidesPerColumn: 1,
          },
          300: {
            slidesPerView: 1,
            spaceBetween: 0,
            slidesPerColumn: 1,
          },
        },
      },
    };
  },
  components: {
    Modal,
    Loader,
    Swiper,
    SwiperSlide
  },
  created () {
    this.unitcontent = this.baseunitcontent
  },
  computed: {
    ...mapGetters(['getApprovalIcon']),
    ...mapState({
      librarySlug: state => state.librarySlug
    }),
    swiper() {
      return this.$refs.mySwiperA.$swiper;
    },
    getGradeClassifications () {
      if (!this.currentGrade) {
        return [];
      }
      // Get selected collection
      const slug = User.getGradeSlug(this.currentGrade) + '_copy' // To homepage
      const grade = this.getGradeBySlug(slug);
      let classifications = []
      // Get all exist classifications in the collection that associated to the primary classification.
      grade.classes.forEach((classNode) => {
        classNode.classifications.forEach(c => {
          if (c.key===this.primaryClassificationTitle)
            classifications.push(c)
        })
      })

      // Remove duplicates
      classifications = [...new Set(classifications.map(JSON.stringify))].map(JSON.parse)
      return  classifications
    },
    getGradeClasses() {
      if (!this.currentGrade) {
        return [];
      }
      const slug = User.getGradeSlug(this.currentGrade) + '_copy'
      const grade = this.getGradeBySlug(slug);
      if (this.currentClassification == -1) {
        return grade.classes;
      }
      return grade.classes.filter((classNode) => {
        console.log(classNode);
        const classifications = classNode.classifications.map(
          (classification) => classification.id
        );
        return classifications.includes(this.currentClassification);
      });
    },
  },
  watch: {
    baseunitcontent() {
      this.unitcontent = this.baseunitcontent
      const selectedGrade = 1
      this.handleSelectGrade(selectedGrade);
    },
  },
  methods: {
    ...mapActions(['openEntryToPreview']),
    getSelectedClassification (card) {
      return card.classifications.filter(c => c.key === 'סוג משימה')
    },
    async handleSelectGrade(grade) {
      // If the collection doesn't exist yet, We ask it and add it to list of classes
      const slug = User.getGradeSlug(grade) + '_copy'
      const c = this.getGradeBySlug(slug);
      if (!c)
        await this.$api.getHome("collections/" + this.librarySlug + "-grade-" + grade + "_copy/")
            .then(async response => {
              const new_class = await this.$store.dispatch('buildUnitContent',response)
              this.unitcontent = this.unitcontent.concat(new_class)
            })
      this.handleSelectClassification(-1)
      this.currentGrade = grade;
    },
    handleSelectClassification(val) {
      this.currentClassification = val;
    },
    getGradeBySlug(slug) {
      return this.unitcontent.filter((classNode) => classNode.slug == slug)[0];
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
    showDescCart(val) {
      this.currentCartDesc = val;
    },
  },
};
</script>

<style lang="scss">
.section-one {
  background-image: linear-gradient(to bottom, rgba(248, 251, 253, 0), #eff6fb 124%);
  position: relative;
  padding-bottom: 80px;
  background: #eff6fb;
  header {
    padding-top: 0;
    position: relative;
    h1 {
      font-size: 70px;
      font-weight: 900;
      line-height: 2.3;
      text-align: center;
      color: #469ca4;
      padding: 0;
    }
    .dubi {
      position: absolute;
      top: -18px;
      right: -7%;
      opacity: 1;
      @media (max-width: 1600px) {
        right: -9%;
        opacity: 1;
      }
      @media (max-width: 1200px) {
        right: -14%;
        opacity: 1;
      }
      @media (max-width: 1024px) {
        top: 10%;
        right: -14%;
        width: 280px;
        height: 280px;
        opacity: 1;
      }
      @media (max-width: 959px) {
        top: -10%;
        right: -10%;
        width: 230px;
        height: 230px;
        opacity: 1;
      }
      @media (max-width: 768px) {
        top: -10%;
        right: -10%;
        width: 200px;
        height: 200px;
        opacity: 0;
      }
      @media (max-width: 668px) {
        top: -0%;
        right: -8%;
        width: 160px;
        height: 160px;
      }
      @media (max-width: 600px) {
        top: 30%;
        right: -8%;
        width: 120px;
        height: 120px;
      }
      @media (max-width: 500px) {
        top: 30%;
        right: -8%;
        width: 100px;
        height: 100px;
      }
    }
  }
  @media (max-width: 1024px) {
    header h1 {
      font-size: 55px;
      line-height: 3.3;
    }
  }
  @media (max-width: 959px) {
    header h1 {
      font-size: 45px;
      line-height: 3;
    }
  }
  @media (max-width: 768px) {
    header  h1 {
      font-size: 40px;
      line-height: 3;
    }
  }
  .section-one-books {
    position: relative;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .scope {
      width: 167.6px;
      height: 97px;
      -o-object-fit: contain;
      object-fit: contain;
      position: absolute;
      inset-inline-end: 0;
      top: 43%;
    }
    @media (max-width: 1450px) {
      .scope {
        display: none;
      }
    }
    .rocket {
      position: absolute;
      left: -4vw;
      width: 20vw;
      @media (max-width: 720px){
        top: -10vh;
      }

      @media (min-width: 721px){
        top: -25vh;
      }
      @media (max-width: 1050px) {
        width: 250px;
        height: 250px;
      }
      @media (max-width: 959px) {
        width: 250px;
        height: 250px;
      }
      @media (max-width: 768px) {
        left: -2%;
        width: 220px;
        height: 220px;
      }
      @media (max-width: 720px) {
        left: -4%;
        width: 200px;
        height: 200px;
      }
      @media (max-width: 600px) {
        left: -5%;
        width: 180px;
        height: 180px;
      }
      @media (max-width: 500px) {
        left: 0;
        width: 140px;
        height: 140px;
      }
      @media (max-width: 450px) {
        left: 0;
        width: 100px;
        height: 100px;
      }
    }
    .card-container {
      max-width: 28%;
      margin:70px 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @media (max-width: 959px) {
        max-width: 48%;
      }

      .item-card {
        border: solid 1px #979797;
        border-radius: 29px;
        position: relative;
        &:hover {
          border: solid 1px transparent;
        }
        .image-btn {
          border-radius: 28px;
          text-description: none;
          width: 308.3px;
          height: 339px;
          transition: ease 0.5s;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          &:hover {
            background-color: #549ead;
            border: solid 1px transparent;
            cursor: pointer;
            .text {
              color: #fff;
            }
          }
          img {
            position: absolute;
            top: -20%;
            right: 3%;
            max-width: 100%;
            height: 90%;
            object-fit: cover;
          }
          .text {
            font-size: 25px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 3.64;
            letter-spacing: normal;
            text-align: center;
            color: #404048;
          }
        }
      }
    }
  }
  .under-line {
    max-width: 300px;
    border-top: 1px solid #9ab8dc;
    margin: 0 auto;
    height: 15px;
    margin-top: 30px; margin-bottom: 20px;
    @media (max-width: 959px) {
      margin-inline: auto;
    }
  }
  .section-two-tech-unit {
    position: relative;
    padding-top: 0;
    @media (max-width: 959px) {
      padding-top: 0;
    }
    header {
      padding: 0;
      @media (max-width: 959px) {
        padding: 0;
        h1 {
          padding: 0;
        }
      }
    }
    .points-container {
      max-width: 500px;
      margin: 0 auto;
      padding-block: 15px;
      .title {
        font-size: 32.4px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: start;
        color: #469ca4;
        margin-left: 25px;
        white-space: nowrap;
        font-family: "Almoni Neue DL 4.0 AAA", sans-serif !important;
        @media (max-width: 768px) {
          font-size: 25px;
        }
        @media (max-width: 568px) {
          font-size: 23px;
          margin-left: 0;
        }
      }
      .class-point {
        width: fit-content;
        min-width: 44px;
        height: 44px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 8px;
        cursor: pointer;
        @media (max-width: 959px) {
          width: 40px;
          height: 40px;
        }
        @media (max-width: 768px) {
          width: 34px;
          height: 34px;
          margin: 0 6px;
        }
        &.active {
          background-color: #469ca4a6;
        }
        p {
          font-size: 32.4px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          color: #3b3b3b;
          margin-bottom: 0;
          @media (max-width: 959px) {
            font-size: 21px;
          }
        }
      }
    }
    .selected-class {
      max-width: 85vw;
      margin: 0 auto;
      padding-bottom: 25px;
      @media (max-width: 625px) {
        justify-content: center;
      }
      p {
        margin: 0 15px;
        font-size: 24px;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #939393;
        cursor: pointer;
        border-bottom: solid 4px transparent;
        @media (max-width: 959px) {
          font-size: 21px;
        }
        @media (max-width: 768px) {
          font-size: 19px;
          margin: 0 12px;
        }
        @media (max-width: 625px) {
          font-size: 17px;
          margin: 0 10px;
          justify-content: center;
        }
        &.active {
          color: black;
          border-bottom: solid 4px #469ca4;
          font-weight: bold;
        }
      }
    }
  }
  .swiper-box {
    display: flex; position: relative;
    .b-l {
      display: flex;
      align-items: center;
      padding-left: 85px;
      @media (max-width: 1500px) {
       padding-left: 75px;
      }
      @media (max-width: 1400px) {
        padding-left: 65px;
      }
      @media (max-width: 1300px) {
        padding-left: 45px;
      }
      @media (max-width: 1200px) {
        padding-left: 35px;
      }
      @media (max-width: 1100px) {
        padding-left: 25px;
      }
      @media (max-width: 1000px) {
        padding-left: 0;
      }
      .swiper-b-next {
        padding: 0 15px;
        @media (max-width: 768px) {
          padding: 0 10px;
        }
        img {
          width: 35px;
          height: 35px;
          @media (max-width: 768px) {
            width: 30px;
            height: 30px;
          }
          @media (max-width: 668px) {
            width: 28px;
            height: 28px;
          }
          @media (max-width: 568px) {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .swipe {
      width: 100%;
      .swiper-slide {
        width: 100%;
        height: auto;
      }
      .swiper-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        .item-box {
          position: relative;
          margin: 14px;
          .card-image {
            height: 300px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 15px;
              object-fit: cover;
            }
            .content-wrapper-digital {
              cursor: pointer;
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              background-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0.01) 47%,
                #000000 113%
              );
              border-radius: 15px;
              .info-box {
                height: 75%;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                .box {
                  width: 70px;
                  height: 70px;
                  border-top-left-radius: 15px;
                  border-bottom-right-radius: 15px;
                  background-color: #469ca4;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 24.2px;
                  color: wheat;
                  position: absolute;
                  z-index: 99;
                  @media (max-width: 768px) {
                    width: 60px;
                    height: 60px;
                  }
                  .i {
                    position: absolute;
                    left: 17%;
                    width: 42px;
                    height: 42px;
                    cursor: pointer;
                    @media (max-width: 768px) {
                      left: 20%;
                      width: 34px;
                      height: 30px;
                    }
                  }
                }
              }
              .content-box-cart {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                padding-inline:30px;
                width: 100%;
                p {
                  font-size: 30px;
                  font-weight: bold;
                  line-height: 1;
                  color: white;
                  margin-bottom: 0;
                }
                .sub {
                  padding: 15px 0;
                  @media (max-width: 959px) {
                    padding: 5px 0;
                  }
                  .line-h {
                    color: white;
                    padding: 0 5px;
                  }
                  p {
                    font-size: 23px;
                    font-weight: 300;
                    @media (max-width: 959px) {
                      font-size: 18px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .b-r {
      display: flex;
      align-items: center;
      padding-right: 85px;
      @media (max-width: 1500px) {
        padding-right: 75px;
      }
      @media (max-width: 1400px) {
        padding-right: 65px;
      }
      @media (max-width: 1300px) {
        padding-right: 45px;
      }
      @media (max-width: 1200px) {
        padding-right: 35px;
      }
      @media (max-width: 1100px) {
        padding-right: 25px;
      }
      @media (max-width: 1000px) {
        padding-right: 0;
      }
      .swiper-b-prev {
        padding: 0 15px;
        @media (max-width: 768px) {
          padding: 0 10px;
        }
        img {
          width: 35px;
          height: 35px;
          @media (max-width: 768px) {
            width: 30px;
            height: 30px;
          }
          @media (max-width: 668px) {
            width: 28px;
            height: 28px;
          }
          @media (max-width: 568px) {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

a {
  text-decoration: none;
  color: red;
}
.approval-icon{
  width: 167px; height: 97px; position: absolute; inset-inline-end: 0;
  .section-one-books &{bottom: -10px;}
  .swiper-box &{bottom: -60px;}

  @media (max-width: 1200px) {
    display: none;
  }
}
</style>
