<template>
  <v-app>
    <router-view :class="{arb: isArb,
    teacher: user && user.isTeacher(),
    student: !user || !user.isTeacher()}"
                 v-if="(!loading && !isLoggedIn) || (isLoggedIn && user && !loading)"
    />
    <template v-else>
      Loading...
    </template>
  </v-app>
</template>

<script>
  import auth from '@/auth'
  import store from './store'
  import { MUTATION_TYPES } from './store/types'
  export default {
    name: 'App',
    components: {},
    data: () => ({
      loading: false,
    }),
    async created () {
      const slug = this.$route.params.slug;
      store.commit(MUTATION_TYPES.SET_LIBRARY_SLUG, slug);
      await store.dispatch('setLang', slug);
      if (this.$auth.token) {
        this.loading = true
        const isAuth = this.$auth.checkAuthenticatedUser()
        if (!isAuth) {
          await this.$router.replace({ name: 'base' })
        }
        this.loading = false
      }
    },
    computed: {
      isLoggedIn () {
        return this.$auth.token
      },
      user () {
        return this.$auth.user
      },
      isArb () {
        return this.$store.state.librarySlug === 'ramot-arb'
      }
    }
  }
</script>
<style lang="scss">
  * {
    box-sizing: border-box;
  }
</style>
