<template>
  <div class="monthly-recommended-main">
    <Loader v-if="!recommended"/>
    <div class="monthly-recommended" v-if="recommended">
      <header>
        <h1>{{$t('MONTHLY_RECOMMENDED')}}</h1>
      </header>

      <div class="swiper-box">
        <div class="b-r">
          <div class="swiper-btn-prev">
            <img alt="" src="@/assets/icons/left-arrow.svg"/>
          </div>
        </div>
        <swiper
            :options="swiperOptions"
            class="swipe"
            ref="mySwiper"
        >
          <swiper-slide :key="index" v-for="(card, index) in recommended">
            <div class="item-box">
              <img :src="card.image" alt=""/>
                <div class="content-wrapper" @click="openEntryToPreview({ entry: card })">
                  <div class="info-box">
                    <div @click="$event.stopPropagation(); showDescCart(card)" class="box">
                      <img
                          @click="$event.stopPropagation(); showDescCart(card)"
                          alt=""
                          class="i"
                          src="@/assets/icons/info.svg"
                      />
                    </div>
                  </div>
                  <div class="content-box-cart d-flex flex-column">
                    <p>{{ card.title }}</p>
                    <div class="sub d-flex" v-if="getSelectedClassification(card)">
                      <div class="d-flex flex-row align-center"
                           v-for="(classification, index) in getSelectedClassification(card)">
                        <p>{{ classification.value }}</p>
                        <div class="line-h" v-if="index<getSelectedClassification(card).length-1">|</div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="b-l">
          <div class="swiper-btn-next">
            <img alt="" src="@/assets/icons/right-arrow.svg"/>
          </div>
        </div>
      </div>
      <Modal
          :currentCartDesc="currentCartDesc"
          :showDescCart="showDescCart"
          :isContentUnit="false"
      />
    </div>
  </div>
</template>

<script>
  import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper'
  import Modal from '../components/Modal'
  import Loader from '../components/Loader'
  import { mapActions } from 'vuex'

  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

  export default {
    props: ['recommended'],
    name: 'carrousel',
    data () {
      return {
        currentCartDesc: null,
        useBy: true,
        swiperOptions: {
          initialSlide: 0,
          slidesPerView: 3,
          spaceBetween: 0,
          pagination: {
            el: '.swiper-pagination',
          },
          navigation: {
            nextEl: '.swiper-btn-next',
            prevEl: '.swiper-btn-prev',
          },
          breakpoints: {
            1400: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            1300: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            1100: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            959: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            540: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            400: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            300: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          },
        },
      }
    },
    components: {
      Modal,
      Loader,
    },
    computed: {
      swiper () {
        return this.$refs.mySwiper.$swiper
      },
    },
    mounted () {
      console.log('Current Swiper instance object', this.swiper)
      this.swiper.slideTo(3, 1000, false)
    },
    methods: {
      ...mapActions(['openEntryToPreview']),
      getSelectedClassification (card) {
        return card.classifications.filter(c => c.key === 'סוג משימה')
      },
      showDescCart (val) {
        this.currentCartDesc = val
      },
    },
  }
</script>
<style lang="scss">
  .monthly-recommended {
    position: relative;

    header {
      min-height: 91px;
      padding-top: 100px;
      padding-bottom: 80px;

      h1 {
        font-size: 70px;
        font-weight: 900;
        line-height: 1.3;
        text-align: center;
        color: #469ca4;
      }
    }

    .swiper-box {
      display: flex;

      .b-l {
        display: flex;
        align-items: center;
        padding-left: 85px;
        @media (max-width: 1500px) {
          padding-left: 75px;
        }
        @media (max-width: 1400px) {
          padding-left: 65px;
        }
        @media (max-width: 1300px) {
          padding-left: 45px;
        }
        @media (max-width: 1200px) {
          padding-left: 35px;
        }
        @media (max-width: 1100px) {
          padding-left: 25px;
        }
        @media (max-width: 1000px) {
          padding-left: 0;
        }

        .swiper-btn-next {
          padding: 0 15px;

          img {
            width: 35px;
            height: 35px;
            @media (max-width: 768px) {
              width: 30px;
              height: 30px;
            }
            @media (max-width: 668px) {
              width: 28px;
              height: 28px;
            }
            @media (max-width: 568px) {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      .swipe {
        width: 100%;

        .swiper-slide {
          width: 100%;
          height: auto;
        }

        .swiper-wrapper {
          flex-direction: row;

          .item-box {
            max-width: 100%;
            position: relative;
            margin: 14px;
            cursor: pointer;
            height: 300px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 15px;
              position: relative;
            }

            .content-wrapper {
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              border-radius: 15px;
              background-image: linear-gradient(
                      to bottom,
                      rgba(0, 0, 0, 0),
                      rgba(0, 0, 0, 0.01) 47%,
                      #000000 113%
              );

              .info-box {
                display: flex;
                justify-content: flex-end;
                height: 75%;

                .box {
                  width: 70px;
                  height: 73px;
                  border-top-left-radius: 15px;
                  border-bottom-right-radius: 15px;
                  background-color: #469ca4;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 34.2px;
                  color: wheat;
                  position: relative;
                  @media (max-width: 768px) {
                    width: 60px;
                    height: 60px;
                  }

                  .i {
                    position: absolute;
                    left: 17%;
                    width: 42px;
                    height: 42px;
                    cursor: pointer;
                    @media (max-width: 768px) {
                      left: 20%;
                      width: 34px;
                      height: 34px;
                    }
                  }
                }
              }

              .content-box-cart {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                padding: 0 30px;
                width: 100%;
                p {
                  font-size: 30px;
                  font-weight: bold;
                  line-height: 1;
                  color: white;
                  margin-bottom: 0px;
                }
                .sub {
                  padding: 15px 0;
                  @media (max-width: 959px) {
                    padding: 5px 0;
                  }
                  .line-h {
                    color: white;
                    padding: 0 5px;
                  }
                  p {
                    font-size: 23px;
                    font-weight: 300;
                    @media (max-width: 959px) {
                      font-size: 18px;
                    }
                  }
                }
              }
            }

            .description {
              position: absolute;
              top: 10%;
              left: 0;
              right: 0;
              padding: 0 25px;

              .description-wrapper {
                background: white;
                border-radius: 15px;
                padding: 5px 5px;
                opacity: 0;
                transition: ease-in 0.3s;

                &:hover {
                  opacity: 1;
                }

                p {
                  font-size: 17px;
                }
              }
            }
          }
        }
      }

      .b-r {
        display: flex;
        align-items: center;
        padding-right: 85px;
        @media (max-width: 1500px) {
          padding-right: 75px;
        }
        @media (max-width: 1400px) {
          padding-right: 65px;
        }
        @media (max-width: 1300px) {
          padding-right: 45px;
        }
        @media (max-width: 1200px) {
          padding-right: 35px;
        }
        @media (max-width: 1100px) {
          padding-right: 25px;
        }
        @media (max-width: 1000px) {
          padding-right: 0;
        }

        .swiper-btn-prev {
          padding: 0 15px;

          img {
            width: 35px;
            height: 35px;
            @media (max-width: 768px) {
              width: 30px;
              height: 30px;
            }
            @media (max-width: 668px) {
              width: 28px;
              height: 28px;
            }
            @media (max-width: 568px) {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }

    .turbina {
      position: absolute;
      left: 0;
      z-index: 1;
      width: 350px;
      top: 73%;
      display: flex;
      @media (max-width: 959px) {
        display: none;
      }
    }
  }
  @media (min-width: 960px){
    .monthly-recommended{padding-bottom: 200px;}
  }
  @media (max-width: 959px) {
    .monthly-recommended {
      position: relative;
      padding-bottom: 20px;

      header {
        min-height: 91px;
        padding-top: 40px;
        padding-bottom: 30px;

        h1 {
          font-size: 50px;
          font-weight: 900;
          line-height: 1.3;
          text-align: center;
          color: #1a7580;
        }
      }

      .swiper {
        max-width: 1400px;
        margin: 0 auto;

        .swiper-wrapper {
          flex-direction: row;

          .item-box {
            max-width: 100%;
            position: relative;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              object-fit: content;
              border-radius: 15px;
              position: relative;
            }

            .content-wrapper {
              position: absolute;
              top: 0%;
              right: 0;
              left: 0;
              width: 100%;
              display: flex;
              flex-direction: column;

              &:hover {
                .description {
                  opacity: 1;
                }

                // border: 10px solid red;
              }

              .info-box {
                display: flex;
                justify-content: flex-end;
                padding: 15px;

                .box {
                  width: 50px;
                  height: 53px;
                  border-top-left-radius: 15px;
                  border-bottom-right-radius: 15px;
                  background-color: #469ca4;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 24.2px;
                  color: wheat;
                }
              }

              .content-box-cart {
                height: 9vh;
                display: flex;
                align-items: flex-end;
                padding: 15px 30px;

                p {
                  font-size: 30px;
                  font-weight: bold;
                  line-height: 1;
                  color: white;
                  margin-bottom: 0px;
                }
              }
            }

            .description {
              position: absolute;
              top: 10%;
              left: 0;
              right: 0;
              padding: 0 25px;

              .description-wrapper {
                background: white;
                border-radius: 15px;
                padding: 5px 5px;
                opacity: 0;
                transition: ease-in 0.3s;

                &:hover {
                  opacity: 1;
                }

                p {
                  font-size: 17px;
                }
              }
            }
          }
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: 30px;
        height: 30px;
        background: transparent;
        position: absolute;
        top: 75%;
        transform: translateY(-50%);
        border-radius: 50%;
        z-index: 9999;
        color: transparent;
        display: none;
      }

      .swiper-button-prev {
        left: 160px;
        right: auto;
      }

      .swiper-button-next {
        right: 160px;
        left: auto;
      }
    }
  }
</style>
