<template>
  <div class="page-wrap classes-page">
    <div class="head">
      <h1>{{$t('MY_LESSONS')}}</h1>
    </div>
    <v-container class="d-flex flex-row flex-wrap">
      <div
        class="col-6 col-sm-4 col-md-3"
        v-for="(element, index) in assigments"
        :key="index"
      >
        <div class="alex">
          <img :src="element.icon" />
          <div class="d-flex flex-row content-wrapper">
            <div class="col-8">
              <p>{{ element.title }}</p>
            </div>
            <div class="col-2 mail">
              <img :src="element.iconmail" />
              <div v-if="element.val" class="val-point">
                <p>{{ element.val }}</p>
              </div>
            </div>
            <div class="col-2 circle">
              <div class="circle-procces">
                <p>0%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "tasks",
  data() {
    return {
      assigments: [
        {
          title: "תעלומת חזירי",
          icon: require("@/assets/icons/pig.svg"),
          iconmail: require("@/assets/icons/envelope.svg"),
          val: 1,
        },
        {
          title: "מי אכל את פאול",
          icon: require("@/assets/icons/owlbg.svg"),
          iconmail: require("@/assets/icons/envelope.svg"),
          val: 3,
        },
        {
          title: "החברים של אור",
          icon: require("@/assets/icons/forest.svg"),
          iconmail: require("@/assets/icons/envelope.svg"),
          val: 2,
        },
        {
          title: "ללמוד מן הטבע",
          icon: require("@/assets/icons/zuk.svg"),
          iconmail: require("@/assets/icons/envelope.svg"),
        },
        {
          title: "הסוואה וחיקוי",
          icon: require("@/assets/icons/greezzuk.svg"),
          iconmail: require("@/assets/icons/envelope.svg"),
        },
        {
          title: "מי פחד מהדנאית ?",
          icon: require("@/assets/icons/parpar.svg"),
          iconmail: require("@/assets/icons/envelope.svg"),
        },
        {
          title: "נמלים אוכלים שניצל מוסא",
          icon: require("@/assets/icons/nemalim.svg"),
          iconmail: require("@/assets/icons/envelope.svg"),
        },
        {
          title: "גם חתולים אוכלים מוסא",
          icon: require("@/assets/icons/zuk.svg"),
          iconmail: require("@/assets/icons/envelope.svg"),
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.page-wrap {
  transition: opacity 0.5s ease;
  padding-top: 50px;
  .col-6 {
    .alex {
      // width: 380px;
      // height: 320px;
      border-radius: 20px;
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
        border-radius: 20px;
        display: block;
        margin: 0 auto;
      }
      .content-wrapper {
        position: absolute;
        width: 100%;
        padding: 0 14px;
        top: 75%;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        .col-8 {
          color: white;
          font-size: 27px;
          font-weight: 400;
          p {
            line-height: 1.3rem;
          }
        }
        .mail {
          position: relative;
          img {
            width: 40px;
            height: 40px;
            border-radius: 0px;
            position: relative;
          }
          .val-point {
            position: absolute;
            top: 10%;
            left: 0;
            right: 5%;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 2px solid white;
            background: red;
            display: flex;
            align-content: space-between;
            justify-content: space-around;
            p {
              color: white;
              text-align: center;
            }
          }
        }
        .circle {
          .circle-procces {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            border: 3px solid white;
            display: flex;
            align-content: space-between;
            justify-content: space-around;
            p {
              color: white;
              font-size: 18px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
