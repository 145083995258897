<template>
  <div class="error-message-container">
    <h2 class="title-modal semi-bold">{{ $t('ERROR_LOGIN_TITLE') }},</h2>
      <p class="text">{{ $t('ERROR_LOGIN_MSG') }}.</p>
      <p class="text contact-us">
        {{ $t('CONTACT_US') }}:
        <a href="mailto:anat.samkai@ramot.org" class="link">anat.samkai@ramot.org</a>
      </p>
  </div>
</template>

<script>

  export default {
    name: 'notPurchasedError',
    data () {
      return {}
    }
  }
</script>
<style lang="scss" scoped>
.contact-us{margin-top: 1rem;}
</style>
