import helpers from './helpers'
import store from './store'
import { MUTATION_TYPES } from './store/types'
import axios from 'axios'
import Vue from 'vue'

export default class Auth {
  static _instance

  constructor () {
    this.authUrl = helpers.env('AUTH_URL') + '/dj-rest-auth/'

    this.axiosClient = axios.create({
      baseURL: this.authUrl,
      headers: this.getHeaders(),
      withCredentials: true
    })

    this.axiosBaseAuth = axios.create({
      baseURL: helpers.env('AUTH_URL'),
      headers: this.getHeaders(),
      withCredentials: true
    })

    this.axiosClient.interceptors.request.use(
      (config) => {
        config.headers = this.getHeaders()

        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    this.axiosBaseAuth.interceptors.request.use(
      (config) => {
        config.headers = this.getHeaders()

        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  getHeaders (json = true) {
    const token = sessionStorage.getItem('token')
    let headers = {
      Accept: 'application/json'
    }

    if (json) {
      headers['Content-Type'] = 'application/json'
    }
    if (token) {
      headers.Authorization = `JWT ${token}`
      const jwt = helpers.decodeJWT(token)
      const exp = jwt.exp || Date.now
      const diff = Date.now() - exp * 1000
      if (!exp || diff > 0) {
        console.log('current diff: ' + diff)
        alert('session expired')
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('school')
        location.reload()
      }
    }
    return headers
  }

  // Checks if the user is authenticated and redirects
  async checkAuthenticatedUser () {
    if (store.getters['token']) {
      const getArbSchool = store.state.librarySlug === 'ramot-arb'
      const isAuthenticated = store.dispatch('fetchAuthenticatedUser', getArbSchool)
      return isAuthenticated
    }
    return false
  }

  resetPassword (details) {
    return this.axiosClient.post('password/reset/', {
      email: details.email,
      username: details.username,
      url: details.url
    })
  }

  changePassword (passwordObj) {
    return this.axiosClient.post('password/reset/confirm/', passwordObj)
  }

  logout (loginErrorMessage) {
    let url = helpers.env('AUTH_URL') + '/accounts/idm/logout/?next=' + location.origin + '/' + store.state.librarySlug
    if (loginErrorMessage) {
        url += '/login-error/' + loginErrorMessage
      }
    location.href = url
  }
  getUserSchools () {
    return this.axiosBaseAuth.get('/user-schools/')
  }
  auth (username, password, captcha) {
    // make api call , and set the response of user and token to the commit below
    return this.axiosClient.post('login/', { username, password, ...(captcha && { captcha }) })
  }

  get_jwt() {
      return this.axiosClient.get('jwt/')
  }

  get token () {
    return store.getters['token']
  }

  get user () {
    return store.getters['user']
  }

  get teacher () {
    return store.getters['teacher']
  }

  /**
   *
   * @returns {Auth}
   */
  static instance () {
    if (!Auth._instance) {
      Auth._instance = new Auth()
    }

    return Auth._instance
  }
}
