<template>
  <div @keydown.esc="closeResetPopup"
       class="modal active-desc reset-password">
    <loader style="z-index: 1; background: transparent;" v-if="isPopupLoading"></loader>
    <div class="modal-container d-flex">
      <div class="d-flex flex-row head-modal">
        <p>{{$t('RESET_PASSWORD')}}</p>
        <button class="close" @click="closeResetPopup">
           <img src="@/assets/icons/closewhite.svg" alt="close icon" />
        </button>
      </div>
      <section class="popup-main-content">
        <div>
          <div v-if="passwordSent" class="success">
            <p class="text">{{ $t('PASS_SENT_MSG') }}.</p>
            <p>{{ $t('DO_NOT_SEE_CHECK_SPAM') }}.</p>
          </div>
          <form v-else class="d-flex flex-column" @submit="resetPassword">
            <input type="text" :placeholder="$t('USERNAME')" v-model="username"
                   @input="$v.username.$touch()"
                   :class="{ error: $v.username.$error }"/>
            <span class="error-text" v-if="$v.username.$error">
                  {{ $t('USERNAME_MISSING') }}
            </span>
            <input type="email" :placeholder="$t('EMAIL')" v-model="email"
                   @input="$v.email.$touch()"
                   :class="{ error: $v.email.$error }">
            <span class="error-text" v-if="$v.email.$error && !$v.email.required">
                  {{ $t('EMAIL_MISSING') }}
            </span>
            <span
                class="error-text"
                v-if="$v.email.required && !$v.email.email">
                  {{ $t('EMAIL_INVALID') }}
            </span>
            <button type="submit" :class="hasErrors ? 'disabled' : null">
              {{$t('SEND')}}
            </button>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'
  import { mapState } from 'vuex'
  import Loader from '@/components//Loader'

  export default {
    name: 'ResetPassword',
    components: { Loader },
    data () {
      return {
        passwordSent: false,
        username: null,
        email: null,
      }
    },
    validations: {
      username: {
        required
      },
      email: {
        required,
        email
      }
    },

    methods: {
      resetPassword (event) {
        event.preventDefault()
        if (this.hasErrors) return
        this.$store.dispatch('setPopupLoading', true)
        const url = window.location.origin + '/' + this.librarySlug + '/confirm-password/'
        this.$store.dispatch('resetPassword', { username: this.username, email: this.email, url:url }
        ).then((response) => {
            this.passwordSent = true
            this.$store.dispatch('setPopupLoading', false)
            setTimeout(() => {
              // timeout added so user can see message that new password was sent to users Email
              this.closeResetPopup()
            }, 5000)
          }
        ).catch(error => {
          console.log(error)
          this.$store.dispatch('setPopupLoading', false)
        })
      },
      closeResetPopup () {
        this.$router.push({ name: 'home' })
      }
    },
    computed: {
      hasErrors () {
        return !this.$v.username.required || !this.$v.email.required || !this.$v.email.email
      },
      ...mapState({
        isPopupLoading: state => state.isPopupLoading,
        librarySlug: state => state.librarySlug,

      }),
    }
  }
</script>
<style lang="scss">
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: #0000005e;
    align-items: center;
    justify-content: center;
    display: none;

    &.active-desc {
      display: flex;
      z-index: 999;
    }

    form {

      .disabled {
        background: #d5d5d5;
        pointer-events: none;
      }

      .error {
        border: 2px solid red;
        background-color: transparent !important
      }

      .error-text {
        margin-right: 5px;
        font-size: 12px;
        color: #FF0000;
      }

      input {
        height: 47px;
        border: 1px solid #707070;
        border-radius: 15px;
        font-size: 20px;
        padding: 0 15px;
        margin: 10px 0;
        outline: none;
      }

      button {
        background: #1ab2c4;
        color: white;
        text-align: center;
        font-size: 30px;
        border-radius: 17px;
        cursor: pointer;
      }
    }

    @-webkit-keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }
    @keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }

    .modal-container {
      background: white;
      border-radius: 25px;
      -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      position: relative;
      .close {
        padding: 8px 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        inset-inline-end: 10px;
        img{all: unset; top: 0; position: static;}

        svg {
          width: 15px;
          height: 15px;
          margin-top: 8px;
        }
      }
    }

    @-webkit-keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }
    @keyframes slide-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
      }
      100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
      }
    }

    .modal-container {
      background: white;
      border-radius: 15px;
      @media all and (min-width: 960px){
        min-width: 450px; max-width: 650px;
      }
      @media all and (min-width: 601px) and (max-width: 959px) {
        max-width: 550px;
      }
      @media all and (min-width: 541px) and (max-width: 600px) {
        max-width: 510px;
      }
      @media all and (min-width: 501px) and (max-width: 540px) {
        max-width: 470px;
      }
      @media all and (min-width: 441px) and (max-width: 500px) {
        max-width: 400px;
      }
      @media all and (min-width: 381px) and (max-width: 440px) {
        max-width: 390px;
      }
      @media (max-width: 380px) {
        max-width: 315px;
      }

      .close {
        img {
          width: 15px;
          height: 15px;
        }
      }


      .popup-main-content {
        padding: 7%;

        .success {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 5%;
          padding-right: 10%;
          padding-left: 10%;
          background-color: transparent !important;

          .text {
            font-size: 24px;
          }

          p {
            font-size: 18px;
          }
        }
      }

      .head-modal {
        background: #1ab2c4;
        padding: 0 20px;
        justify-content: space-between;
        align-items: center;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        text-align: center;
        p {
          font-size: 30px;
          color: white;
          margin-bottom: 0;
          font-weight: bold;
          padding: 10px 0;
          padding-right: 32%;
        }

        .close {
          svg {
            width: 20px;
            height: 20px;
            margin-top: 8px;
          }
        }
      }

      ::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #1ab2c4;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
</style>
